import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import { Link } from "react-router-dom";
import useLanguage from "../../state/hooks/setLingua";

const Container = styled.div`
    border-radius: 10px;
    width: 43%;
    height: 200px;
    margin: 2em 1%;
    padding: 20px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: 1s ease-in;
    color: transparent;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    background-color: transparent;
    @media screen and (max-width: ${style.screens.medium}) {
        width: 100%;
    }

    & div {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        animation: ${props => props.isVisible ? 'start 0.8s linear' : 'none'};
        @keyframes start {
            0% {
                transform: scale(0);
                opacity: 1;
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
        }
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -15px;
        right: -15px;
        background: ${props => props.bgColor};
        height: 250px;
        width: 25px;
        border-radius: 32px;
        transform: scale(1);
        transform-origin: 100% 50%;
        transition: transform 1s ease-out;
    }

    &:hover::before {
        transition-delay: .3s;
        transform: scale(40);
    }

    &:hover {
        color: #ffffff;
    }

    & p {
        padding: 10px 0;
        font-size: 14px;
        font-family: ${style.typography.fontFamily.light};
    }

    & h4 {
        font-size: ${style.typography.fontSize.p};
        font-family: ${style.typography.fontFamily.bold};
        color: #ffffff;
    }

    & svg {
        width: 60px;
        height: 60px;
        transition: all 0.4s;
    }
    
    &:hover svg {
        opacity: 0;
        transition-delay: 0.2s;
    }
    
    & button {
        padding: 0.3em 2em;
        font-size: 12px;
        border-radius: 4px;
        border: none;
        margin-top: 0.5em;
        background-color: transparent;
        color: transparent;
        cursor: pointer;
        font-family: ${style.typography.fontFamily.regular};
        transition: all 0.4s;
    }
    
    &:hover button {
        transition-delay: 0.4s;
        background-color: ${style.colors.secondary};
        color: ${style.colors.darkGray};
    }
`

const CardProduto = ({ icone, nome, descricao, bgColor, link }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    const { language } = useLanguage();

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const top = ref.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;
                if (top < windowHeight) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    };


    return (
        <Container ref={ref} bgColor={bgColor} isVisible={isVisible}>
            <h4>{nome}</h4>
            <p>{descricao}</p>
            <div>
                <Link onClick={scrollToTop} style={{ textDecoration: 'none' }} to={link}><button>{language === 'pt-br' ? 'Ver mais' : 'See more'}</button></Link>
                {icone}
            </div>
        </Container>
    );
};

export default CardProduto;

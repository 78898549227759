import CommandCenterPage from "./pages/CommandCenter";
import Homepage from "./pages/Homepage";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SensebotPage from "./pages/Sensebot";
import RadPage from "./pages/Rad";
import SobrePage from "./pages/Sobre";
import ServicosPage from "./pages/Servicos";
import ContatoPage from "./pages/Contato";
import PageNotFound from "./pages/404";
import ParceiriaXlabs from "./pages/Parcerias/xlabs";
import RecebiveisPage from "./pages/Recebiveis";
import LgpdPage from "./pages/Lgpd";
import SegurancaInfoPage from "./pages/SegurancaInformacao";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="command-center" element={<CommandCenterPage />} />
        <Route path="nexus" element={<RecebiveisPage />} />
        <Route path="sensebot" element={<SensebotPage />} />
        <Route path="rad" element={<RadPage />} />
        <Route path="servicos" element={<ServicosPage />} />
        <Route path="sobre" element={<SobrePage />} />
        <Route path="contato" element={<ContatoPage />} />
        <Route path="lgpd" element={<LgpdPage />} />
        <Route path="seguranca-informacao" element={<SegurancaInfoPage />} />
        <Route path="parcerias/xlabs" element={<ParceiriaXlabs />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import { style } from "../../styles";

const Container = styled.div`
    width: 100%;
    height: 5rem;
    color: ${props => props.fontColor};
    margin-bottom: 3rem;
`

const Line = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.fontColor}
`;

const Icone = styled.div`
    width: ${props => props.qtdIcone * 20}px;
    height: 20px;
    background-color: ${props => props.bgColor};
    position: absolute;
    top: -10px;
    left: calc(50% - ${props => props.qtdIcone * 10}px);
    display: flex;
    align-items: center;
    justify-content: center;
`

const Title = styled.h3`
    width: 100%;
    height: ${style.typography.fontSize.h3};
    font-size: ${style.typography.fontSize.h3};
    font-family: ${style.typography.fontFamily.medium};
    text-align: center;
    font-weight: 100;
    margin-bottom: ${props => props.subtitulo ? '0px' : '20px'};

    @media screen and (max-width: ${style.screens.medium}) {
        font-size: ${style.typography.fontSize.h4};
    }
`

const Subtitulo = styled.h4`
    width: 100%;
    height: ${style.typography.fontSize.h4};
    font-size: ${style.typography.fontSize.h4};
    font-family: ${style.typography.fontFamily.regular};
    text-align: center;
    margin-bottom: 20px;
    font-weight: 100;

    @media screen and (max-width: ${style.screens.medium}) {
        font-size: ${style.typography.fontSize.p};
    }
`

const Titulo = ({ color, bgColor, icone, titulo, subtitulo, qtdIcone }) => (
    <Container fontColor={color}>
        <Title subtitulo={subtitulo}>{titulo}</Title>
        {
            subtitulo ?
            <Subtitulo>{subtitulo}</Subtitulo> : null
        }
        <Line fontColor={color}>
            <svg width="100%" height="1" viewBox="0 0 100 1" preserveAspectRatio="none">
                <line stroke={color} x1="0" y1="0" x2="100" y2="0" strokeWidth="1"
                    strokeDasharray="1 0.5 2 0.5" />
            </svg>
            <Icone qtdIcone={qtdIcone} bgColor={bgColor}>
                {icone}
            </Icone>
        </Line>
    </Container>
);

export default Titulo;
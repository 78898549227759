import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import contatoImage from '../../assets/images/photo/cafs-xlabs.png'

import { ReactComponent as Logo } from '../../assets/images/photo/cafs-colorido.svg';
import { ReactComponent as Circulos } from '../../assets/images/photo/circulos.svg';

import { ReactComponent as Telefone } from '../../assets/images/icon/telefone.svg';
import { ReactComponent as Email } from '../../assets/images/icon/email.svg';
import { ReactComponent as Local } from '../../assets/images/icon/local.svg';

import Secao from "../../components/Secao";
import styled from "styled-components";

import { ReactComponent as Error } from '../../assets/images/icon/error.svg';
import { ReactComponent as Sucesso } from '../../assets/images/icon/sucesso.svg';
import { useState } from "react";
import Footer from "../../components/Footer";
import SendMessage from "../../controllers/SendMessage";
import axios from "axios";
import sha256 from 'js-sha256';
import InputComponent from "../../components/Input";

const Container = styled.div`
    width: 100%;
    height: 550px;
    background-color: ${style.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    @media screen and (max-width: ${style.screens.medium}) {
        flex-direction: column;
        height: auto;
    }
`

const Infos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: ${style.colors.primary};
    border-radius: 8px;
    justify-content: start;
    width: 40%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    color: ${style.colors.white};
    position: relative;
    overflow: hidden;

    @media screen and (max-width: ${style.screens.medium}) {
        width: 100%;
        height: 550px;
    }
`

const Titulo = styled.h4`
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: ${style.typography.fontFamily.medium};
    font-size: ${style.typography.fontSize.h4};
`

const Message = styled.p`
    width: 100%;
    text-align: center;
    font-family: ${style.typography.fontFamily.light};
    font-size: 14px;
    margin-bottom: 1.5rem;
`

const Info = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 1rem;
`

const IconeInfo = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ConteudoInfo = styled.p`
    width: 70%;
    margin-left: 1rem;
    font-family: ${style.typography.fontFamily.regular};
    font-size: 14px;
`

const Forms = styled.form`
    width: 60%;
    height: 100%;
    display: flex;
    padding: 3rem;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    box-sizing: border-box;

    @media screen and (max-width: ${style.screens.medium}) {
        width: 100%;
        flex-direction: column;
    }
`

const Mensagem = styled.div`
    width: 350px;
    height: 50px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 4px;
    box-shadow: 3px 3px 5px 2px #00000050;
    background-color: ${props => props.error ? '#DC1A1A' : '#00804b'};
    font-family: ${style.typography.fontFamily.medium};
    color: ${style.colors.white};
    font-size: ${style.typography.fontFamily.p};
    position: absolute;
    top: 15px;
    z-index: 3;
    right: calc(50% - 150px);
    animation: aparecer 2s;
    box-sizing: border-box;

    @keyframes aparecer {
        0% {
            top: -80px;
            display: flex;
        } 90% {
            top: 15px;
            display: flex;
        } 100% {
            display: none;
        }
    }

    @media screen and (max-width: ${style.screens.medium}) {
        top: 30%;
        right: calc(50% - 175px);
        animation: aparecer2 2s;

        @keyframes aparecer2 {
            0% {
                top: calc(30% - 80px);
                display: flex;
            } 90% {
                top: 30%;
                display: flex;
            } 100% {
                display: none;
            }
        }
    }
`

const ContainerBotao = styled.div`
    width: 30%;
    padding: 0px 1rem;
    height: 2.5rem;
    border: 2px solid ${style.colors.primary};
    border-radius: 4px;
    background-color: ${style.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .8s;
    text-decoration: none;
    margin-left: calc(70% - 2rem);

    @media screen and (max-width: ${style.screens.medium}) {
        width: calc(100% - 2rem);
        margin-left: 0px;
    }

    &:hover {
        background-color: ${style.colors.white};
        border: 2px solid ${style.colors.primary};
    }

    &:hover > p {
        color: ${style.colors.primary};
    }
`

const Text = styled.p`
    font-family: ${style.typography.fontFamily.regular};
    font-weight: 100;
    color: ${style.colors.white};
    width: 100%;
    height: auto;
    text-align: center;
    font-size: ${style.typography.fontSize.p};
`

const Loading = styled.div`
    display: inline-block;
    position: relative;
    width: 31.25px;
    height: 2.5rem;

    & div {
        box-sizing: border-box;
    }

    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 1rem;
        height: 1rem;
        margin: 8px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    & div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    & div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    & div:nth-child(3) {
        animation-delay: -0.15s;
    }
    
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }


`

const ParceiriaXlabs = () => {
    const [send, setSend] = useState(false);
    const [error, setError] = useState(false);
    const [mensagemError, setMensagemError] = useState('');

    const [sendFinish, setSendFinish] = useState(false);

    const [nome, setNome] = useState('');
    const [errorNome, setErrorNome] = useState(false);

    const [sobrenome, setSobrenome] = useState('');
    const [errorSobrenome, setErrorSobrenome] = useState(false);

    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);

    const [telefone, setTelefone] = useState('');
    const [errorTelefone, setErrorTelefone] = useState(false);

    const [ramo, setRamo] = useState('');
    const [errorRamo, setErrorRamo] = useState(false);

    const [local, setLocal] = useState('');
    const [errorLocal, setErrorLocal] = useState(false);

    const clearInputs = () => {
        setNome('');
        setSobrenome('');
        setEmail('');
        setTelefone('');
        setRamo('');
        setLocal('');
    }

    const handleErros = (camposComErro) => {
        camposComErro.forEach((campo) => {
            switch (campo) {
                case 'nome':
                    setErrorNome(true);
                    break;
                case 'sobrenome':
                    setErrorSobrenome(true);
                    break;
                case 'email':
                    setMensagemError("Email é obrigatório")
                    setErrorEmail(true);
                    break;
                case 'telefone':
                    setErrorTelefone(true);
                    break;
                default:
                    break;
            }
        });
    };

    const limparErros = () => {
        setErrorNome(false);
        setErrorSobrenome(false);
        setErrorEmail(false);
        setErrorTelefone(false);
        setErrorRamo(false);
        setErrorLocal(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSend(true);

        if(local.length === 0) {
            setMensagemError("Preencha os campos obrigatórios");
            setError(true);
            setErrorLocal(true);
            setSend(false);
            setSendFinish(true);
            return;
        } 
        
        if (ramo.length === 0) {
            setMensagemError("Preencha os campos obrigatórios");
            setError(true);
            setErrorRamo(true);
            setSend(false);
            setSendFinish(true);
            return;
        }

        const chaveSecreta = process.env.REACT_APP_SECRET;
        const timestamp = new Date().getTime();
        const token = timestamp + '-' + sha256(chaveSecreta + timestamp);

        const assunto = `Contato pela parceiria da Xlabs`
        const mensagem = `Localização: ${local} - Ramo de atividade: ${ramo}`

        const sendMessage = new SendMessage({ nome, sobrenome, email, telefone, assunto, mensagem });
        const result = await sendMessage.sendMessage();

        if (result.error) {
            const campos = result.campos;

            if (campos && campos.length > 0) {
                handleErros(campos);
            } else {
                if (result.message === "Telefone") {
                    setErrorTelefone(true)
                } else {
                    setMensagemError("Email inválido")
                    setErrorEmail(true);
                }
            }

            setTimeout(() => {
                setSend(false);
            }, 3500);

            return;
        } else {
            try {
                await axios.post(process.env.REACT_APP_URL_SEND_EMAIL, { ...result.data }, {
                    headers: { token }
                })

                limparErros()
                setSend(false);
                setError(false);
                setSendFinish(true);
                clearInputs();

                setTimeout(() => {
                    setSendFinish(false);
                }, 3500);
            } catch (err) {
                limparErros();
                setSend(false);
                setMensagemError("Erro no envio")
                setError(true);
                setSendFinish(true);

                setTimeout(() => {
                    setSendFinish(false);
                }, 3500);
            }

        }

    };


    return (
        <>
            <Funcbar backgroundColor={style.colors.primary} />
            <Banner
                img={contatoImage}
                titulo={['']}
                subtitulo={['']}
                corGradient='#2B2B2B00'
                colorida={true}
                logo={<Logo />}
            />
            <Secao
                bgColor={style.colors.secondary}
                conteudo={
                    <Container>
                        {
                            sendFinish ?
                                <Mensagem error={error}>
                                    {
                                        error ?
                                            <>
                                                <Error width={22} height={22} />
                                                <p>{mensagemError}</p>
                                            </> :
                                            <>
                                                <Sucesso width={22} height={22} />
                                                <p>Enviado com sucesso</p>
                                            </>

                                    }
                                </Mensagem> : null
                        }
                        <Infos>
                            <div>
                                <Titulo>Proteção avançada para aplicações web de todas as complexidades.</Titulo>
                                <Message>O Web Application Firewall (WAF) proteção contra-ataques DDoS volumétrico, contém inteligência artificial que além de identificar novos ataques, define ações a serem tomadas para a proteção do seu sistema.</Message>
                                <Message>Quer a segurança como sua maior parceira? Preencha os campos para receber um contato.</Message>
                            </div>
                            <div style={{ height: '20%' }}>
                                <Info>
                                    <IconeInfo>
                                        <Telefone />
                                    </IconeInfo>
                                    <ConteudoInfo>(11) 9 4496-2401</ConteudoInfo>
                                </Info>
                                <Info>
                                    <IconeInfo>
                                        <Email />
                                    </IconeInfo>
                                    <ConteudoInfo>info@cafs.com.br</ConteudoInfo>
                                </Info>
                                <Info>
                                    <IconeInfo>
                                        <Local />
                                    </IconeInfo>
                                    <ConteudoInfo>Pr. dos Cravos, 10 - Alphaville Comercial, Barueri - SP, 06453-063</ConteudoInfo>
                                </Info>
                            </div>
                            <Circulos
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    position: 'absolute',
                                    bottom: '-30px',
                                    right: '-30px'
                                }}
                            />
                        </Infos>
                        <Forms>
                            <InputComponent
                                error={errorNome}
                                errorValue={"Nome é obrigatório"}
                                labelValue="Nome"
                                key={0}
                                value={nome}
                                type="text"
                                onChange={(e) => setNome(e.target.value)}
                                onBlur={() => {
                                    if (nome.length > 0) setErrorNome(false)
                                    if (nome.length === 0) setErrorNome(true)
                                }}
                            />
                            <InputComponent
                                error={errorSobrenome}
                                errorValue={"Sobrenome é obrigatório"}
                                labelValue="Sobrenome"
                                key={1}
                                value={sobrenome}
                                type="text"
                                onChange={(e) => setSobrenome(e.target.value)}
                                onBlur={() => {
                                    if (sobrenome.length > 0) setErrorSobrenome(false)
                                    if (sobrenome.length === 0) setErrorSobrenome(true)
                                }}
                            />
                            <InputComponent
                                error={errorEmail}
                                errorValue={mensagemError}
                                labelValue="Email"
                                key={2}
                                value={email}
                                placeholder="exemplo@email.com"
                                type="text"
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => {
                                    if (email.length > 0) setErrorEmail(false)
                                    if (email.length === 0) {
                                        setMensagemError("Email é obrigatório")
                                        setErrorEmail(true)
                                    } else if (!/\S+@\S+\.\S+/.test(email)) {
                                        setMensagemError("Email inválido");
                                        setErrorEmail(true);
                                    } else {
                                        setErrorEmail(false);
                                    }
                                }}
                            />
                            <InputComponent
                                error={errorTelefone}
                                errorValue={"Telefone inválido"}
                                labelValue="Telefone"
                                key={3}
                                placeholder="(XX) XXXX-XXXX"
                                value={telefone}
                                type="text"
                                onChange={(e) => setTelefone(e.target.value)}
                                onBlur={() => {
                                    if (!(/^\d{11}$/.test(telefone) || /^\d{10}$/.test(telefone)) && telefone.length > 0) {
                                        setErrorTelefone(true)
                                    } else {
                                        setErrorTelefone(false)
                                    }
                                }}
                            />
                            <InputComponent
                                labelValue="Ramo de Atividade"
                                error={errorRamo}
                                key={4}
                                value={ramo}
                                type="text"
                                onChange={(e) => setRamo(e.target.value)}
                                errorValue={"Ramo de Atividade é obrigatório"}
                                onBlur={() => {
                                    if (ramo.length > 0) setErrorRamo(false)
                                    if (ramo.length === 0) setErrorRamo(true)
                                }}
                            />
                            <InputComponent
                                labelValue="Localização"
                                key={5}
                                error={errorLocal}
                                placeholder="Cidade/Estado"
                                value={local}
                                type="text"
                                onChange={(e) => setLocal(e.target.value)}
                                errorValue={"Localização é obrigatória"}
                                onBlur={() => {
                                    if (local.length > 0) setErrorLocal(false)
                                    if (local.length === 0) setErrorLocal(true)
                                }}
                            />
                            
                            <ContainerBotao onClick={handleSubmit}>
                                {
                                    send ?
                                        <Loading><div></div><div></div><div></div><div></div></Loading> :
                                        <Text>Enviar</Text>
                                }
                            </ContainerBotao>
                        </Forms>
                    </Container>
                }
            />
            <Footer />
        </>
    );
}

export default ParceiriaXlabs;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { style } from '../../styles';

import farmaConde from '../../assets/images/photo/clientes/farmaConde.jpg';
import cutait from '../../assets/images/photo/clientes/cutait.png';
import lis from '../../assets/images/photo/clientes/lis.jpg';
import swift from '../../assets/images/photo/clientes/swift-logo.png';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 450px;

  @media screen and (max-width: ${style.screens.medium}) {
    min-height: 650px;
  }
`;

const displayAnimation = keyframes`
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  5%, 10% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
`;

const Item = styled.div`
  position: absolute;
  border-radius: 12px;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  animation: ${displayAnimation} 30s infinite;
  background-color: ${style.colors.darkGray};
  display: flex;
  flex-direction: row;
  algin-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  color: white;

  @media screen and (max-width: ${style.screens.medium}) {
    flex-direction: column;
    
    & div {
      width: 100%;
      padding: 0 1rem;
    }
  }

  & img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  & div {
    display: flex;
    flex-direction: column;
    algin-items: start;
    justify-content: space-between;
    width: calc(100% - 60px);
    padding: 0 1rem;
    box-sizing: border-box;

    @media screen and (max-width: ${style.screens.medium}) {
      width: 100%;
      padding: 0 .5rem;
    }
  }

  & div h3 {
    font-size: ${style.typography.fontSize.h3};
    font-family: ${style.typography.fontFamily.regular};
    font-weight: 100;
  }

  & div h4 {
    font-size: ${style.typography.fontSize.p};
    font-family: ${style.typography.fontFamily.regular};
    font-weight: 100;
  }

  & div p {
    font-size: 15px;
    font-family: ${style.typography.fontFamily.light};
    font-weight: 100;
    margin-bottom: 2rem;
  }
`;

const items = [
    { image: farmaConde, title: 'Farma Conde', pessoa: 'Alexandre Rosati - CIO', descricao: '"...Em um grupo em crescimento acelerado, em meio a uma pandemia global, encarar uma transformação cultural, sistêmica e alavancar a transformação digital de uma empresa em crescimento acelerado, pensar em superar as barreiras da obsolescência tecnológica e a implementação de processos de forma eficiente, sem as fantasias e promessas comuns dos grandes players é algo que somente com os sólidos conhecimentos técnicos, a correta prioridade e toda a habilidade gerencial da CAFs é possível de se fazer. Encontramos alguém para ser nosso elo de operação nessa jornada, com a capacidade de mostrar, interferir, propor e implementar soluções de forma cirúrgica, no timming certo e dentro da viabilidade financeiro do negócio..."' },
    { image: cutait, title: 'CUTAIT - Cirurgia Plástica', pessoa: 'Dr. Victor Cutait - CEO', descricao: '"Um dos maiores desafios das empresas é a tecnologia, principalmente quando o serviço que prestamos não inclui essa atividade, somos dependentes dela mas que isso somos reféns desse novo universo. Contar com uma empresa idônea, prestativa, eficiente e principalmente disponível é o sonho de qualquer empresário. Foi exatamente isso que encontrei ao contratar os serviços de suporte tecnológico da CAFS. Resolvem o problema cirurgicamente, sem delongas, com simplicidade e eficiência!! Estou muito satisfeito com a CAFS, hoje posso me focar única e exclusivamente no meu trabalho pois sei que sempre e quando precisar eles estarão de prontidão. Mais que recomendar eu indico!!!"' },
    { image: lis, title: 'LIS - Iluminação', pessoa: 'Igor - Diretor Operacional', descricao: '"Nós da Lis Iluminação recomendamos os trabalhos da CAFS. Uma empresa altamente qualificada, com um atendimento impecável e um senso profissional admirável! Adoramos trabalhar junto a essa empresa!"' },
    { image: swift, title: 'Swift', pessoa: 'Angelo Santos - CIO', descricao: '"A CaFs teve um entendimento detalhado da demanda e do negócio. Ajudou a ajustar o escopo para que os entregáveis fossem de fato uteis para a Swift. Compreendeu e ajustou a solução (conjunto de ferramentas) para que seguissem todas as normas de segurança e políticas do grupo JBS. Aqui na empresa, temos um valor que consideramos crucial para nosso negócio, que é a Disponibilidade. Podemos dizer que a CaFs tem esse valor enraizado em todas as pessoas com as quais tivemos contato."' },
  ];

const Clientes = () => {
  return (
    <Container>
        {
            items.map((item, index) => (
                <Item style={{ animationDelay: `${index * 6}s` }}>
                    <img src={item.image} />
                    <div>
                        <h3>{item.title}</h3>
                        <p>{item.descricao}</p>
                        <h4>{item.pessoa}</h4>
                    </div>
                </Item>
            ))
        }
    </Container>
  );
};

export default Clientes;

import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import recebiveisImage from '../../assets/images/photo/recebiveis.jpg'
import { ReactComponent as Logo } from '../../assets/images/icon/nexusColorido.svg';
import { ReactComponent as IconNexus } from '../../assets/images/icon/nexus.svg';
import { ReactComponent as Foguete } from '../../assets/images/icon/foguete.svg';
import { ReactComponent as Icone } from '../../assets/images/icon/info.svg';

import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

import { ReactComponent as Onboarding } from '../../assets/images/icon/onboarding.svg';
import { ReactComponent as Opercao } from '../../assets/images/icon/psc.svg';
import { ReactComponent as Portabilidade } from '../../assets/images/icon/portabilidade.svg';
import { ReactComponent as ConsultaAgenda } from '../../assets/images/icon/consulta-agenda.svg';
import { ReactComponent as Setup } from '../../assets/images/icon/setup.svg';
import Botao from "../../components/Botao";
import Footer from "../../components/Footer";
import Chatbot from "../../components/Chatbot";
import InfoRegistradoras from "../../components/InfoRegistradoras";
import useLanguage from "../../state/hooks/setLingua";

const RecebiveisPage = () => {
  const { language } = useLanguage();

  return (
    <>
      <Funcbar backgroundColor={style.colors.nexus} />
      <Chatbot />
      <Banner
        img={recebiveisImage}
        titulo={['Nexus']}
        subtitulo={language === 'pt-br' ? ['Recebíveis sob controle,', 'crescimento seguro'] : ['Receivables under control,', 'secure growth']}
        corGradient='#2B2B2B00'
        colorida={false}
        logo={<Logo />}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Descrição do serviço' : 'Service Description'}
        bgColor={style.colors.secondary}
        color={style.colors.nexus}
        conteudo={
          <>
          {
            language === 'pt-br' ?
            <ConteudoText color='#000'>
              Nosso serviço de Recebíveis é projetado para fornecer controle total e otimização das suas operações de recebíveis de cartão com credenciadoras e sub credenciadoras. Oferecemos uma gama abrangente de soluções para garantir que todos os seus recebíveis estejam devidamente registrados no mercado, facilitando a conciliação com os dados do seu ERP. Isso permite que você obtenha melhores ofertas de crédito e antecipe corretamente os recebíveis quando necessário.
              <br></br>
              <br></br>
              Entregamos nossas soluções de maneira flexível, através do nosso Portal de Clientes, Dashboard, Envio de Arquivos e outras plataformas. Além disso, cuidamos de todo o trâmite de contestação em caso de irregularidades e acionamos o Banco Central se os prazos legais não forem respeitados.
              <br></br>
              <br></br>
              Em breve, disponibilizaremos um serviço de validação de billing para registradoras, garantindo que você esteja sendo cobrado de acordo com seu contrato e uso das plataformas.
              <br></br>
              <br></br>
              Nosso serviço garante uma gestão eficiente e sem burocracia dos seus recebíveis, proporcionando a confiança de que suas operações estão otimizadas e em conformidade com as melhores práticas do mercado.
            </ConteudoText> :
            <ConteudoText color='#000'>
            Our Receivables service is designed to provide complete control and optimization of your card receivables operations with merchant acquirers and sub-acquirers. We offer a comprehensive range of solutions to ensure that all your receivables are properly registered on the market, facilitating reconciliation with your ERP data. This allows you to obtain better credit offers and correctly finance your receivables (invoice factoring) if needed.             <br></br>
            <br></br>
            We deliver our solutions flexibly through our Customer Portal, Dashboard, File Submission (bulk/batch processing), and other platforms. Additionally, we handle all the dispute processes in case of irregularities and contact the Central Bank if legal deadlines are not respected.
            <br></br>
            <br></br>
            Soon, we will provide a billing validation service for clearing houses and credit bureaus, ensuring that you are being charged according to your contract and platform usage.
            <br></br>
            <br></br>
            Our service guarantees efficient and hassle-free management of your receivables, ensuring your operations are optimized and in compliance with market best practices. 
          </ConteudoText>
          }
          </>
        }
        icone={<IconNexus />}
        qtdIcone={1}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Principais benefícios' : 'Main Benefits'}
        bgColor={style.colors.nexus}
        color={style.colors.darkGray}
        conteudo={
          <>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Consulta de Agendas' : 'Agenda Consultation'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Nossa solução permite a conciliação precisa dos recebíveis registrados com os dados do seu ERP. Cuidamos da contestação de irregularidades e acionamos o Banco Central se necessário, sem custos adicionais' : 'Our solution allows for the precise reconciliation of registered receivables with your ERP data. We take care of disputing irregularities and contact the Central Bank if necessary, at no additional cost'}
                icone={<ConsultaAgenda />}
              />
              <Card
                tituloPrimeiro={false}
                titulo='Onboarding'
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Oferecemos suporte completo para o onboarding em registradoras de recebíveis, agilizando o processo sem necessidade de mobilização do seu time de tecnologia. Em alguns casos conseguimos realizar o processo de Onboarding em até 2 dias úteis' : 'We offer full support for onboarding to clearing houses, speeding up the process without the need to mobilize your technology team. In some cases, we can complete the Onboarding process in as little as 2 business days'}
                icone={<Onboarding />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Operação' : 'Operation'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Gerenciamos o ambiente de recebíveis para você, com documentação completa e suporte via diversos canais, incluindo WhatsApp. Usamos melhores práticas de mercado' : 'We manage the receivables environment for you, with complete documentation and support through various channels, including WhatsApp. We use market best practices'}
                icone={<Opercao />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Setup da Operação' : 'Operation Setup'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Realizamos uma análise pontual do seu ambiente de operação e processos, entregando um relatório detalhado com oportunidades de eficiência e possíveis reduções de custos' : 'We conduct a detailed analysis of your operational environment and processes, delivering a detailed report with efficiency opportunities and possible cost reductions'}
                icone={<Setup />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Portabilidade' : 'Portability'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Oferecemos suporte completo para a portabilidade de registradoras de recebíveis, minimizando o trabalho do seu time de tecnologia com nosso time de desenvolvimento de integrações' : 'We offer full support for Clearing House and Bureau portability, minimizing the workload on your technology team with our integration development team'}
                icone={<Portabilidade />}
              />
            </ContainerCard>
            <ConteudoText style={{ fontSize: '24px', fontFamily: style.typography.fontFamily.semiBold }} color='#fff'>{language === 'pt-br' ? 'Oferecemos condições especiais para conexões com a B3' : '  We offer special conditions for connections with B3'}</ConteudoText>
            <Botao link='/contato' fullWidth={true} bgColor={style.colors.nexus} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : 'Contact Us'} />
          </>
        }
        icone={<Foguete />}
        qtdIcone={1}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Infos do Mercado de Recebíveis' : 'Market Information on Receivables'}
        bgColor={style.colors.white}
        color={style.colors.nexus}
        conteudo={
          <>
            <InfoRegistradoras />
          </>
        }
        icone={<Icone />}
        qtdIcone={1}
      />
      
      <Footer />
    </>
  );
}

export default RecebiveisPage;

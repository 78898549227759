import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { style } from '../../styles';
import useLanguage from '../../state/hooks/setLingua';
import sha256 from 'js-sha256';
import styled from 'styled-components';

const Loading = styled.div`
    display: inline-block;
    position: relative;
    width: 31.25px;
    height: 2.5rem;

    & div {
        box-sizing: border-box;
    }

    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 1rem;
        height: 1rem;
        margin: 8px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    & div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    & div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    & div:nth-child(3) {
        animation-delay: -0.15s;
    }
    
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const Svg = styled.div`
    width: 100%;
    height: 20%;

    & svg {
        width: 100%;
        height: 100%;
    }
`

const Captcha = () => {
    const [captchaImage, setCaptchaImage] = useState('');
    const [errorCaptcha, setErrorCaptcha] = useState(false);

    const [captchaResponse, setCaptchaResponse] = useState('');
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [botToken, setBotToken] = useState('');

    const { language } = useLanguage()

    useEffect(() => {
        const storedEmail = sessionStorage.getItem('userEmail');
        if (storedEmail) {
            setEmail(storedEmail);
            setIsEmailValid(true);
        }

    }, []);

    useEffect(() => {
        if (showCaptcha) {
            generateCaptcha();
        }
    }, [showCaptcha]);

    const generateCaptcha = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL_GENERATE_CAPTCHA,
                {
                    withCredentials: true
                }
            );

            setCaptchaImage(response.data);
        } catch (error) {
            setErrorCaptcha(true);
            console.error('Error generating CAPTCHA:', error.message);
        }
    };

    const validateCaptcha = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_URL_VALIDATE_CAPTCHA, { captchaResponse }, {
                withCredentials: true
            });

            if (response.data.success) {
                setIsValid(true);
                await getDirectLineToken();
            } else {
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error validating CAPTCHA:', error.message);
        }
    };

    const getDirectLineToken = async () => {
        try {
            const tokenResponse = await axios.post('https://directline.botframework.com/v3/directline/tokens/generate', {}, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_DIRECT_LINE_SECRET}`
                }
            });
            setBotToken(tokenResponse.data.token);
        } catch (error) {
            console.error('Error generating Direct Line token:', error.message);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(e.target.value);
        setIsEmailValid(isValid);
        if (isValid) {
            sessionStorage.setItem('userEmail', e.target.value);
        }
    };

    const sendEmail = async () => {
        try {
            const chaveSecreta = process.env.REACT_APP_SECRET;
            const timestamp = new Date().getTime();
            const token = timestamp + '-' + sha256(chaveSecreta + timestamp);

            await axios.post(process.env.REACT_APP_URL_SEND_EMAIL,
                {
                    nome: email,
                    sobrenome: "",
                    email: email,
                    telefone: "",
                    assunto: `[Sensebot] - Site <${email}>`,
                    mensagem: `O usuário com o email ${email} abriu o sensebot na pagina do Nexus e possivelmente usou o bot`
                }, {
                headers: { token }
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const handleShowCaptcha = async () => {
        if (isEmailValid) {
            await sendEmail();
            setShowCaptcha(true);
        }
    };

    return (
        <div style={{ width: '100%', height: '90%' }}>
            {!showCaptcha && (
                <>
                    <h3 style={{ color: style.colors.darkGray, width: '80%', marginLeft: '10%', cursor: 'default', height: '40%', textAlign: 'center', marginTop: '20%' }} >
                        {language === 'pt-br' ? 'Teste nossa solução de chatbot com generative AI fazendo perguntas sobre a Resolução 264 do Bacen' : 'Test our chatbot solution with generative AI by asking questions about Bacen Resolution 264'}
                    </h3>
                    <input
                        style={{ width: '100%', boxSizing: 'border-box', fontFamily: style.typography.fontFamily.light, fontSize: '14px', padding: '.3rem .5rem', marginTop: '1rem' }}
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder={language === 'pt-br' ? "Digite seu e-mail" : 'Enter your email'}
                    />
                    {!isEmailValid && email && (
                        <p
                            style={{
                                width: '100%',
                                fontFamily: style.typography.fontFamily.medium,
                                fontSize: '14px',
                                textAlign: 'start',
                                color: style.colors.sensebot
                            }}
                        >
                            {language === 'pt-br' ? 'E-mail inválido, tente novamente' : 'Invalid email, please try again'}
                        </p>
                    )}
                    <button onClick={handleShowCaptcha} disabled={!isEmailValid} style={{ width: '100%', fontFamily: style.typography.fontFamily.light, fontSize: '14px', cursor: isEmailValid ? 'pointer' : 'not-allowed', margin: '.5rem 0' }}>
                        {
                            language === 'pt-br' ? 'Seguir' : 'Next'
                        }
                    </button>
                </>
            )}
            {showCaptcha && (
                <>
                    {isValid ? 
                        <Loading><div></div><div></div><div></div><div></div></Loading>
                    :
                        <>
                            {
                                !errorCaptcha ?
                                    <>
                                        <Svg dangerouslySetInnerHTML={{ __html: captchaImage }} />
                                        <input
                                            style={{ width: '100%', boxSizing: 'border-box', fontFamily: style.typography.fontFamily.light, fontSize: '14px', padding: '.3rem .5rem', marginTop: '1rem' }}
                                            type="text"
                                            value={captchaResponse}
                                            onChange={(e) => setCaptchaResponse(e.target.value)}
                                            placeholder={language === 'pt-br' ? "Digite o código" : 'Enter the code'}
                                        />
                                    </> :
                                    <p
                                        style={{
                                            width: '100%',
                                            fontFamily: style.typography.fontFamily.medium,
                                            fontSize: '14px',
                                            margin: '.5rem 0',
                                            textAlign: 'center',
                                            color: style.colors.sensebot
                                        }}
                                    >
                                        {
                                            language === 'pt-br' ? 'Erro ao carregar CAPTCHA' : 'Error loading CAPTCHA'
                                        }
                                    </p>
                            }
                            {isValid === false &&
                                <p
                                    style={{
                                        width: '100%',
                                        fontFamily: style.typography.fontFamily.medium,
                                        fontSize: '14px',
                                        textAlign: 'start',
                                        color: style.colors.sensebot
                                    }}
                                >
                                    {
                                        language === 'pt-br' ? 'Código incorreto, tente novamente' : 'Incorrect code, please try again'
                                    }
                                </p>
                            }
                            <button disabled={errorCaptcha} onClick={validateCaptcha} style={{ width: '100%', fontFamily: style.typography.fontFamily.light, fontSize: '14px', cursor: !errorCaptcha ? 'pointer' : 'not-allowed', margin: '.5rem 0' }}>{language === 'pt-br' ? 'Verificar' : 'Verify'}</button>
                        </>
                    }
                    {isValid === true && botToken &&
                        <iframe
                            src={`https://webchat.botframework.com/embed/bot-sensebot-site?t=${botToken}&username=${email}`}
                            style={{ width: '100%', height: '90%' }}>
                        </iframe>
                    }
                </>
            )}
        </div>
    );
};

export default Captcha;

class SendMessage {
    constructor({ nome, sobrenome, email, telefone, assunto, mensagem }) {
        this.nome = nome
        this.sobrenome = sobrenome
        this.email = email
        this.telefone = telefone
        this.assunto = assunto
        this.mensagem = mensagem
    }

    validateRequired() {
        const campos = [];

        if(!this.nome) {
            campos.push('nome');
        }

        if(!this.sobrenome) {
            campos.push('sobrenome')
        }

        if(!this.email) {
            campos.push('email')
        }

        if(!this.mensagem) {
            campos.push('mensagem')
        }

        if(campos.length > 0) {
            return { error: true, message: 'Preencha os campos obrigatórios', campos }
        }

        return { error: false }
    }

    validateEmail() {
        if(!/\S+@\S+\.\S+/.test(this.email)) {
            return { error: true, message: "Email" }
        } else {
            return { error: false }
        }

    }

    validateTelefone() {
        if(!(/^\d{11}$/.test(this.telefone) || /^\d{10}$/.test(this.telefone))) {
            return { error: true, message: "Telefone" }
        } else {
            return { error: false }
        }
    }

    async sendMessage() {
        if(this.validateRequired().error) {
            return this.validateRequired()
        }

        if(this.validateEmail().error) {
            return this.validateEmail();
        }

        if(this.validateTelefone().error && this.telefone.length > 0) {
            return this.validateTelefone();
        }

        return { error: false, data: this }
    }
}

export default SendMessage;
import React from "react";
import { style } from "../../../styles";
import Secao from "../../../components/Secao";
import { ReactComponent as IconParceiros } from '../../../assets/images/icon/parceiros.svg';
import Parceiros from "../../../components/Parceiros";
import useLanguage from "../../../state/hooks/setLingua";

const SectionParceiros = () => {
  const { language } = useLanguage();

  return(
    <Secao
        id='parceiros'
        titulo={language === 'pt-br' ? 'Parceiros' : 'Partners'}
        bgColor={style.colors.white}
        color={style.colors.darkGray}
        conteudo={<Parceiros />}
        icone={<IconParceiros />}
        qtdIcone={1}
      />
  )
}

export default SectionParceiros;
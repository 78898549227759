import React from "react";
import styled from "styled-components"
import { style } from "../../styles";
import { ReactComponent as Icone } from '../../assets/images/icon/areaCliente.svg';
import { ReactComponent as WhatsApp } from '../../assets/images/icon/whatsapp.svg';
import { ReactComponent as Instagram } from '../../assets/images/icon/instagram.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/icon/linkedin.svg';
import Lingua from "../Lingua";
import useLanguage from '../../state/hooks/setLingua';

const Container = styled.div`
    height: 30px;
    width: 60%;
    padding: 0px 20%;
    margin: 0px;
    border-bottom: 1px solid ${style.colors.secondary};
    
    @media screen and (max-width: ${style.screens.medium}) {
        width: 90%;
        padding: 0px 5%;
        height: 40px;
    }
`;

const DivAll = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between 
`

const BotaoAreaCliente = styled.a`
    height: 20px;
    width: 150px;
    background-color: white;
    font-family: ${style.typography.fontFamily.regular};
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border-radius: 2px;
    padding: 0px 5px;
    color: black;
    cursor: pointer;
    transition: all .6s;
    border: 1px solid transparent;
    text-decoration: none;
    
    @media screen and (max-width: ${style.screens.medium}) {
        height: 24px;
    }

    &:hover {
        background-color: transparent;
        color: white;
        border: 1px solid white;
    }
`

const DivRedesSocial = styled.div`
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const BotaoRedeSocial = styled.a`
    height: 20px;
    width: 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    transition: all .6s;
    text-decoration: none;

    & svg {
        width: 16px;
        height: 16px;
    }

    @media screen and (max-width: ${style.screens.medium}) {
        height: 24px;
        width: 24px;

        & svg {
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        background-color: transparent;
        color: white !important;
    }
`

const Funcbar = ({ backgroundColor }) => {
    const { language } = useLanguage();

    return (
        <Container style={{ backgroundColor }}>
            <Lingua />
            <DivAll>
                <BotaoAreaCliente href="https://area-cliente.cafs.com.br" target="_blank">
                    <Icone
                        width='20px'
                        height='20px'
                        style={{
                            marginRight: '15px',
                        }}
                    />
                    { language === 'pt-br' ? 'Área de Cliente' : 'Client Area'}
                </BotaoAreaCliente>
                <DivRedesSocial style={{ color: backgroundColor }}>
                    <BotaoRedeSocial style={{ color: backgroundColor }} href="https://api.whatsapp.com/send?phone=5511944962401" target="_blank">
                        <WhatsApp />
                    </BotaoRedeSocial>
                    <BotaoRedeSocial style={{ color: backgroundColor }} href="https://www.linkedin.com/company/cafs-tecnologia/" target="_blank">
                        <LinkedIn />
                    </BotaoRedeSocial>
                    <BotaoRedeSocial style={{ color: backgroundColor }} href="https://www.instagram.com/cafstecnologia/" target="_blank">
                        <Instagram />
                    </BotaoRedeSocial>
                </DivRedesSocial>
            </DivAll>
        </Container>
    )
}


export default Funcbar;
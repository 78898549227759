import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import radImage from '../../assets/images/photo/rad.jpg'

import { ReactComponent as Logo } from '../../assets/images/icon/radColorido.svg';

import { ReactComponent as IconRad } from '../../assets/images/icon/rad.svg';
import { ReactComponent as Dashboard } from '../../assets/images/icon/dashboard.svg';
import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

import Botao from "../../components/Botao";

import { ReactComponent as Mapa } from '../../assets/images/icon/mapa.svg';
import { ReactComponent as BannerIcon } from '../../assets/images/icon/banner.svg';
import { ReactComponent as Cemaden } from '../../assets/images/icon/cemaden.svg';
import { ReactComponent as Trem } from '../../assets/images/icon/trem.svg';
import { ReactComponent as Clima } from '../../assets/images/icon/clima.svg';
import { ReactComponent as Bias } from '../../assets/images/icon/bias.svg';
import { ReactComponent as Psc } from '../../assets/images/icon/psc.svg';
import { ReactComponent as Transito } from '../../assets/images/icon/transito.svg';
import Footer from "../../components/Footer";
import useLanguage from "../../state/hooks/setLingua";

const RadPage = () => {
  const { language } = useLanguage();

  return (
    <>
      <Funcbar backgroundColor={style.colors.rad} />
      <Banner
        img={radImage}
        titulo={['RAD', '(Resilience Analytic Dashboard)']}
        subtitulo={language === 'pt-br' ? ['Seu parceiro para continuidade, resiliência e controle'] : ['Your partner for continuity, resilience, and control']}
        corGradient='#2B2B2B00'
        colorida={false}
        logo={<Logo />}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Descrição do serviço' : 'Service Description'}
        bgColor={style.colors.secondary}
        color={style.colors.rad}
        conteudo={
          <>
            <ConteudoText color='#000'>
              {
                language === 'pt-br' ?
                "Em um mundo repleto de incertezas, é fundamental que as empresas tenham planos de contingência adequados para garantir a sobrevivência e a resiliência do negócio." :
                "In a world full of uncertainties, it is essential for companies to have adequate contingency plans to ensure business survival and resilience."
              }
              <br></br>
              <br></br>
              {
                language === 'pt-br' ?
                "Desenvolvemos um dashboard multifuncional que trata dos principais pilares de resiliência para manter a sua empresa um passo à frente em casos extremos. Com o RAD sua empresa acompanha de forma visual e automatizada situações de possíveis crises, pensando em todos os aspectos de Continuidade" :
                "We have developed a multifunctional dashboard that addresses the main pillars of resilience to keep your company one step ahead in extreme cases. With RAD, your company can visually and automatically monitor possible crisis situations, considering all aspects of Continuity."
              }
            </ConteudoText>
          </>
        }
        icone={<IconRad />}
        qtdIcone={1}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Principais benefícios' : 'Main Benefits'}
        bgColor={style.colors.rad}
        color={style.colors.darkGray}
        conteudo={
          <>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Mapa de funcionários​' : "Employee Map"}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Localização de onde os funcionários estão alocados. Pode ser vinculado às informações do Cemaden.' : "Location of where employees are allocated. It can be linked to Cemaden information."}
                icone={<Mapa />}
              />
              <Card
                tituloPrimeiro={false}
                titulo='Banner'
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Banner que mostra com destaque os principais alertas.​' : "Banner that prominently shows the main alerts."}
                icone={<BannerIcon />}
              />
              <Card
                tituloPrimeiro={false}
                titulo='Cemaden'
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Painel com as informações obtidas no Cemaden, divididas por criticidade.​' : "Panel with information obtained from Cemaden divided by criticality."}
                icone={<Cemaden />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Malha Ferroviária​' : "Railway Network"}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Informações da malha ferroviária de São Paulo.​' : "Information on São Paulo's railway network."}
                icone={<Trem />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Clima' : "Climate"}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Alertas climáticos, divididos por criticidade e seu descritivo​' : "Weather alerts divided by criticality and description."}
                icone={<Clima />}
              />
              <Card
                tituloPrimeiro={false}
                titulo='BIAs'
                color={style.colors.white}
                conteudo={ language === 'pt-br' ? 'Criticidade dos BIAs já documentados​' : "Criticality of documented BIAs."}
                icone={<Bias />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Provedores de Serviços Críticos​' : "Critical Service Providers"}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Integração com os provedores de serviços críticos (PSCs), definidos pelo cliente, com suas respectivas disponibilidades.​​' : "Integration with the client's defined critical service providers (CSPs) and their respective availabilities."}
                icone={<Psc />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Trânsito (CET)​' : "Traffic (CET)"}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Informações do trânsito, em tempo real, na cidade de São Paulo.​​' : "Real-time traffic information in São Paulo."}
                icone={<Transito />}
              />
            </ContainerCard>
            <Botao link='/contato' fullWidth={true} bgColor={style.colors.rad} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : "Talk to us"} />
          </>
        }
        icone={<Dashboard />}
        qtdIcone={1}
      />
      <Footer />
    </>
  );
}

export default RadPage;

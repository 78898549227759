import styled from "styled-components";

const ContainerCard = styled.div`
    width: 100%;
    margin-bottom: 3rem;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`

export default ContainerCard;
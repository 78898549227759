import styled from "styled-components";
import { style } from "../../styles";

const ConteudoText = styled.p`
    width: 85%;
    margin: 0px 7.5%;
    margin-bottom: 6rem;
    height: auto;
    font-size: ${style.typography.fontSize.p};
    font-family: ${style.typography.fontFamily.light};
    font-weight: 100;
    text-align: center;
    color: ${props => props.color};
`

export default ConteudoText;
export const style = {
    colors: {
        primary: "#003F80",
        secondary: "#D9D9D9",
        sensebot: "#FB4747",
        commandCenter: "#76B900",
        rad: "#883C8F",
        nexus: "#f0811d",
        darkGray: "#2B2B2B",
        white: "#FFF"
    },
    screens: {
        xsmall: "479px",
        small: "767px",
        medium: "767px",
        large: "1219px",
        xlarge: "1220px"
    },
    paddings: {
        small: "8px",
        medium: "16px",
        large: "24px"
    },
    margins: {
        small: "8px",
        medium: "16px",
        large: "24px"
    },
    typography: {
        fontFamily: {
            bold: "'Poppins-Bold', Arial, sans-serif",
            semiBold: "'Poppins-SemiBold', Arial, sans-serif",
            regular: "'Poppins-Regular', Arial, sans-serif",
            medium: "'Poppins-Medium', Arial, sans-serif",
            light: "'Poppins-Light', Arial, sans-serif",
        },
        fontSize: {
            h1: '40px',
            h2: '36px',
            h3: '24px',
            h4: '20px',
            p: '16px',
        }
    }
}

// 30% width
// 10% de width para height

import React from "react";
import styled from "styled-components";
import { style } from "../../styles";
import { Link } from "react-router-dom";

const Container = styled.div`
    width: ${props => props.fullWidth ? '100%' : '30%'};
    height: 2.5rem;
    border: 2px solid ${style.colors.secondary};
    border-radius: 4px;
    background-color: ${props => props.bgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .8s;
    text-decoration: none;
    
    @media screen and (max-width: ${style.screens.medium}) {
        width:  ${props => props.fullWidth ? '100%' : '90%'};
    }

    &:hover {
        background-color: ${style.colors.secondary};
        border: 2px solid ${props => props.bgColor};
    }

    &:hover > a > p {
        color: ${props => props.bgColor};
    }
`

const Text = styled.p`
    font-family: ${style.typography.fontFamily.regular};
    font-weight: 100;
    color: ${style.colors.secondary};
    width: 100%;
    height: auto;
    text-align: center;
    font-size: ${props => props.fontSize};
`

const Botao = ({ label, bgColor, fontSize, fullWidth, link }) => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    };

    return(
        <Container fullWidth={fullWidth} bgColor={bgColor}>
            <Link onClick={scrollToTop} style={{ textDecoration: 'none', height: 'auto', width: '100%' }} to={link}>
                <Text fontSize={fontSize}>{label}</Text>
            </Link>
        </Container>
    )
}

export default Botao;
import React from "react";
import { style } from "../../../styles";
import { ReactComponent as IconCafs } from '../../../assets/images/icon/cafs-colorido.svg';
import { ReactComponent as IconRad } from '../../../assets/images/icon/rad.svg';
import { ReactComponent as IconSensebot } from '../../../assets/images/icon/sensebot.svg';
import { ReactComponent as IconNexus } from '../../../assets/images/icon/nexus.svg';

import Secao from "../../../components/Secao";
import ContainerCard from "../../../components/ContainerCard";

import CardProduto from "../../../components/CardProdutos";
import { ReactComponent as CommandCenterColorido } from '../../../assets/images/icon/commandColorido.svg';
import { ReactComponent as SensebotColorido } from '../../../assets/images/icon/sensebotColorido.svg';
import { ReactComponent as RadColorido } from '../../../assets/images/icon/radColorido.svg';
import { ReactComponent as RecebiveisColorido } from '../../../assets/images/icon/nexusColorido.svg';
import useLanguage from "../../../state/hooks/setLingua";

const SectionProdutos = () => {
    const { language } = useLanguage();

    return(
        <Secao
            id='produtos'
            titulo={language === 'pt-br' ? 'Produtos' : 'Products'}
            subtitulo={language === 'pt-br' ? 'Conheça algumas de nossas soluções' : 'Discover some of our solutions'}
            bgColor={style.colors.darkGray}
            color={style.colors.white}
            conteudo={<ContainerCard>
                <CardProduto
                    nome={'Command Center'}
                    icone={<CommandCenterColorido />}
                    descricao={language === 'pt-br' ? 'Ao contratar nosso serviço de Command Center oferecemos, monitoração e observabilidade, processos de Gestão de Incidentes e Problemas, as métricas de negócio selecionadas e muito mais' : 'By hiring our Command Center service, we offer monitoring and observability, Incident and Problem Management processes, selected business metrics, and much more.'}
                    bgColor={style.colors.commandCenter}
                    link='/command-center'
                />
                <CardProduto
                    nome={'Sensebot'}
                    icone={<SensebotColorido />}
                    descricao={language === 'pt-br' ? 'As interações automatizadas e inteligentes se tornaram essenciais para empresas que desejam oferecer uma experiência aprimorada e diferenciada aos seus clientes' : 'Automated and intelligent interactions have become essential for companies that want to offer an enhanced and differentiated experience to their customers.'}
                    bgColor={style.colors.sensebot}
                    link='/sensebot'
                />
                <CardProduto
                    nome={'RAD'}
                    icone={<RadColorido />}
                    descricao={language === 'pt-br' ? 'Ao contratar nosso serviço de Command Center oferecemos, monitoração e observabilidade, processos de Gestão de Incidentes e Problemas, as métricas de negócio selecionadas e muito mais' : 'Our multifunctional dashboard addresses the main pillars of resilience to keep your company ahead in extreme cases.'}
                    bgColor={style.colors.rad}
                    link='/rad'
                />
                <CardProduto
                    nome={'Nexus'}
                    icone={<RecebiveisColorido />}
                    descricao={language === 'pt-br' ? 'Ao contratar nosso serviço de Recebíveis, oferecemos consulta de agendas, conciliação de recebíveis, contestação de irregularidades, suporte para onboarding, operação do ambiente e portabilidade entre registradoras' : 'When hiring our Receivables service, we offer agenda consultation, receivables reconciliation, dispute of irregularities, support for onboarding, operation of the environment, and portability between registrars'}
                    bgColor={style.colors.nexus}
                    link='/nexus'
                />
            </ContainerCard>}
            icone={<>
                <IconCafs />
                <IconSensebot />
                <IconRad />
                <IconNexus />
            </>}
            qtdIcone={3}
        />
    )
}

export default SectionProdutos;
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import Botao from "../Botao";
import Navegacao from "../Navegacao";
import useLanguage from "../../state/hooks/setLingua";

const Container = styled.div`
    position: relative;
    width: 100%;
    height: ${props => props.lg ? '80vh' : '45.5vh'};
    padding-top: 4em 0px;
    box-sizing: border-box;

    @media screen and (min-width: ${style.screens.large}) {
        height: ${props => props.lg ? '80vh' : '60vh'};;
        padding-top: 5em;
        padding-bottom: 4em;
    }

    @media screen and (max-width: ${style.screens.medium}) {
        height: 75vh;
        padding-top: 7rem;
        padding-bottom: 2rem;
    }
`;

const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : 'none'};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    filter: ${props => props.colorida ? 'grayscale(0)' : 'grayscale(1)'};
    transition: all 0.2s ease;
    // opacity: ${props => props.loaded ? 1 : 0};
`;

const Gradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, ${props => props.cor}) 100%;
`;

const Content = styled.div`
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & h2 {
        font-family: ${style.typography.fontFamily.medium};
        font-size: ${style.typography.fontSize.h2};
        font-weight: 100;
        margin-bottom: 15px;
    }

    & h1 {
        font-family: ${style.typography.fontFamily.bold};
        font-size: ${style.typography.fontSize.h1};
        animation: tracking-out-contract 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) reverse both;
    }

    @keyframes tracking-out-contract {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 1;
        }
        100% {
          letter-spacing: -0.5em;
          opacity: 0;
        }
      }      

    & h4 {
        font-family: ${style.typography.fontFamily.regular};
        font-weight: 100;
        font-size: ${style.typography.fontSize.h4};
    }

    @media screen and (max-width: ${style.screens.small}) {
        & h1 {
            font-size: ${style.typography.fontSize.h2};
        }

        & h2 {
            font-size: ${style.typography.fontSize.h3};
        }

        & h4 {
            font-size: ${style.typography.fontSize.p};
        }
    }
`;

const Banner = ({ img, corGradient, titulo, subtitulo, frase, botao, colorida, logo }) => {
    const [loaded, setLoaded] = useState(false);
    const { language } = useLanguage();

    useEffect(() => {
        const image = new Image();
        image.onload = () => {
            setLoaded(true);
        };
        image.src = img;
    }, [img]);

    return (
        <Container lg={botao && frase}>
            <BackgroundImage imageUrl={img} colorida={colorida} loaded={loaded} />
            <Gradient cor={corGradient} />
            <Navegacao logo={logo} />
            <Content>
                <div>
                    <h2>
                        {titulo.map((line, index) => <p key={index}>{line}</p>)}
                    </h2>
                    <h1>
                        {subtitulo.map((line, index) => <p key={index}>{line}</p>)}
                    </h1>
                </div>
                {
                    frase ?
                        <h4>
                            {frase.map((line, index) => <p key={index}>{line}</p>)}
                        </h4>
                        : null
                }
                {
                    botao ?
                        <Botao
                            label={language === 'pt-br' ? 'Fale conosco' : 'Talk to us'}
                            bgColor='#003F8080'
                            fontSize={style.typography.fontSize.h4}
                            link='/contato'
                        >
                            {botao}
                        </Botao> : null
                }
            </Content>
        </Container>
    );
};

export default Banner;

import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import servicosImage from '../../assets/images/photo/servicos.jpg'

import { ReactComponent as Logo } from '../../assets/images/photo/cafs-colorido.svg';
import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

// import { ReactComponent as Objetivo } from '../../assets/images/icon/objetivo.svg';
import { ReactComponent as Gov } from '../../assets/images/icon/gov.svg';
import { ReactComponent as Sustentacao } from '../../assets/images/icon/sustentacao.svg';
import { ReactComponent as Gcn } from '../../assets/images/icon/gcn.svg';
import { ReactComponent as Foguete } from '../../assets/images/icon/foguete.svg';
import styled from "styled-components";
import Botao from "../../components/Botao";
import Titulo from "../../components/Titulos";

import { ReactComponent as NFE } from '../../assets/images/icon/nota-fiscal.svg';
import { ReactComponent as Iot } from '../../assets/images/icon/iot.svg';
import { ReactComponent as Estrategia } from '../../assets/images/icon/estrategia.svg';
import { ReactComponent as Custo } from '../../assets/images/icon/custo.svg';
import Footer from "../../components/Footer";
import useLanguage from "../../state/hooks/setLingua";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    algin-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    @media screen and (max-width: ${style.screens.medium}) {
        flex-wrap: wrap;
    }
`

const ServicosPage = () => {
    const { language } = useLanguage();

    return (
        <>
            <Funcbar backgroundColor={style.colors.primary} />
            <Banner
                img={servicosImage}
                titulo={language === 'pt-br' ? ['Serviços'] : ['Services']}
                subtitulo={language === 'pt-br' ? ['Cuidamos de cada detalhe para criar', 'momentos que deixam uma marca duradoura'] : ['We take care of every detail to create', 'moments that leave a lasting mark']}
                corGradient='#2B2B2B00'
                colorida={false}
                logo={<Logo />}
            />
            <Secao
                bgColor={style.colors.secondary}
                conteudo={
                    <Container>
                        <Card
                            color={style.colors.darkGray}
                            icone={<Gov />}
                            tituloPrimeiro={false}
                            titulo={language === 'pt-br' ? 'Mapeamento de procesos e Gov. de TI' : 'Process Mapping and IT Governance'}
                        />
                        <Card
                            color={style.colors.darkGray}
                            icone={<Sustentacao />}
                            tituloPrimeiro={false}
                            titulo={language === 'pt-br' ? 'Suporte e Sustentação' : 'Support and Maintenance'}
                        />
                        <Card
                            color={style.colors.darkGray}
                            icone={<Gcn />}
                            tituloPrimeiro={false}
                            titulo={language === 'pt-br' ? 'Gestão de Continuidade do Negócio' : 'Business Continuity Management'}
                        />
                        <Card
                            color={style.colors.darkGray}
                            icone={<Foguete />}
                            tituloPrimeiro={false}
                            titulo={language === 'pt-br' ? 'Consultoria de Tecnologia' : 'Technology Consulting'}
                        />
                    </Container>
                }
            />
            <Secao
                id={'mapeamento'}
                bgColor={style.colors.darkGray}
                color={style.colors.secondary}
                qtdIcone={1}
                icone={<Gov />}
                titulo={language === 'pt-br' ? 'Mapeamento de processos e Gov. de TI' : 'Process Mapping and IT Governance'}
                conteudo={
                    <>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                'A implementação do ITIL é uma abordagem estruturada que visa melhorar a eficiência, a qualidade e a entrega de serviços de TI dentro de uma organização.' :
                                'ITIL implementation is a structured approach aimed at improving the efficiency, quality, and delivery of IT services within an organization.'
                            }
                            <br></br>
                            <br></br>
                            {
                                language === 'pt-br' ?
                                'Mapear os processos da sua empresa é uma forma de entender como as atividades são realizadas, quais são os recursos, as pessoas, os riscos e as oportunidades envolvidos em cada uma delas.' :
                                `Mapping your company's processes is a way to understand how activities are carried out, what resources, people, risks, and opportunities are involved in each one.`
                            }
                        </ConteudoText>
                        <ContainerCard>
                            <Card
                                color={style.colors.secondary}
                                conteudo={language === 'pt-br' ? 'O ITIL ajuda a alinhar os serviços de TI com as necessidades e metas do negócio, facilitando o sucesso da organização' : 'ITIL helps align IT services with business needs and goals, facilitating organizational success.'}
                                titulo={language === 'pt-br' ? 'Alinhamento entre TI e negócios' : 'Alignment between IT and Business'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.secondary}
                                conteudo={language === 'pt-br' ? 'Enfatiza a importância da entrega consistente de serviços de alta qualidade, melhorando a satisfação dos clientes' : 'Emphasizes the importance of consistently delivering high-quality services, improving customer satisfaction.'}
                                titulo={language === 'pt-br' ? 'Maior qualidade dos serviços' : 'Higher Quality of Services'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.secondary}
                                conteudo={language === 'pt-br' ? 'Fornece estruturas e processos para gerenciar incidentes e problemas de forma eficiente, minimizando o tempo de inatividade' : 'Provides structures and processes for efficiently managing incidents and problems, minimizing downtime.'}
                                titulo={language === 'pt-br' ? 'Melhor gerenciamento de incidentes e problemas' : 'Better Incident and Problem Management'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.secondary}
                                conteudo={language === 'pt-br' ? 'Com um mapeamento claro dos processos, é possível identificar gargalos, redundâncias ou etapas ineficiente aumentando a eficiência operacional' : 'With a clear process mapping, it is possible to identify bottlenecks, redundancies, or inefficient steps, increasing operational efficiency.'}
                                titulo={language === 'pt-br' ? 'Melhoria contínua' : 'Continuous Improvement'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.secondary}
                                conteudo={language === 'pt-br' ? 'Ao entender claramente cada etapa dos processos, podemos identificar oportunidades de Automação, reduzindo custos, aumentando a velocidade de execução e eliminando erros humanos' : 'By clearly understanding each step of the processes, we can identify automation opportunities, reducing costs, increasing execution speed, and eliminating human errors.'}
                                titulo={language === 'pt-br' ? 'Possibilidade de automação' : 'Automation Opportunities'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.secondary}
                                conteudo={language === 'pt-br' ? 'Com processos bem mapeados, torna-se mais fácil monitorar o desempenho através de indicadores, além de possibilitar ação proativa em caso de falhas' : 'With well-mapped processes, it becomes easier to monitor performance through indicators and take proactive action in case of failures.'}
                                titulo={language === 'pt-br' ? 'Monitoramento' : 'Monitoring'}
                                tituloPrimeiro={false}
                            />
                        </ContainerCard>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                "A implementação requer uma abordagem estruturada, incluindo a definição de papéis e responsabilidades, adaptação dos processos e conscientização e treinamento da equipe. É importante contar com o apoio de especialistas em ITIL para guiar a implementação de acordo com as necessidades específicas da organização" :
                                "Implementation requires a structured approach, including role and responsibility definition, process adaptation, and team awareness and training. It is important to have the support of ITIL specialists to guide the implementation according to the organization's specific needs."
                            }
                            </ConteudoText>
                        <Botao link='/contato' fullWidth={true} bgColor={style.colors.darkGray} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : 'Talk to us'} />
                    </>
                }
            />

            <Secao
                id={'suporte'}
                bgColor={style.colors.white}
                color={style.colors.primary}
                qtdIcone={1}
                icone={<Sustentacao />}
                titulo={language === 'pt-br' ? 'Suporte e Sustentação' : 'Support and Maintenance'}
                conteudo={
                    <>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                "Na era digital em constante evolução, é fundamental ter uma equipe especializada para garantir a operação contínua e eficiente dos seus sistemas de tecnologia. Nosso Serviço de Sustentação N1 e N2 oferece o suporte técnico necessário para manter seus sistemas funcionando de forma estável e confiável." :
                                "In the constantly evolving digital era, it is crucial to have a specialized team to ensure the continuous and efficient operation of your technology systems. Our N1 and N2 Maintenance Service offers the technical support necessary to keep your systems running stably and reliably."
                            }
                        </ConteudoText>
                        <ContainerCard>
                            <Card
                                color={style.colors.primary}
                                conteudo={language === 'pt-br' ? 'Oferecemos suporte técnico ágil para lidar com problemas emergenciais e minimizar o tempo de inatividade dos sistemas. Estamos disponíveis 24/7' : 'We offer agile technical support to handle emergency issues and minimize system downtime. We are available 24/7.'} 
                                titulo={language === 'pt-br' ? 'Resposta rápida' : 'Quick Response'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.primary}
                                conteudo={language === 'pt-br' ? 'Em conjunto com nossa solução de Command Center, monitoramos constantemente seus sistemas, identificando problemas potenciais antes que afetem o desempenho ou a disponibilidade' : 'Together with our Command Center solution, we constantly monitor your systems, identifying potential problems before they affect performance or availability.'}
                                titulo={language === 'pt-br' ? 'Monitoramento proativo' : 'Proactive Monitoring'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.primary}
                                conteudo={language === 'pt-br' ? 'Somos capazes de diagnosticar rapidamente problemas e fornecer soluções eficientes, focando na resolução rápida e precisa de qualquer incidente' : 'We can quickly diagnose issues and provide efficient solutions, focusing on the quick and precise resolution of any incident.'}
                                titulo={language === 'pt-br' ? 'Diagnóstico e solução de problemas' : 'Problem Diagnosis and Resolution'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.primary}
                                conteudo={language === 'pt-br' ? 'Além de lidar com problemas emergenciais, realizamos manutenção preventiva para garantir que seus sistemas estejam atualizados, seguros e funcionando com desempenho máximo' : 'Besides handling emergency issues, we perform preventive maintenance to ensure your systems are up-to-date, secure, and running at maximum performance.'}
                                titulo={language === 'pt-br' ? 'Manutenção preventiva' : 'Preventive Maintenance'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.primary}
                                conteudo={language === 'pt-br' ? 'Adaptamos nossos serviços de sustentação para atender às suas demandas exclusivas e garantir que suas expectativas sejam atendidas.' : 'We adapt our maintenance services to meet your unique demands and ensure your expectations are met.'}
                                titulo={language === 'pt-br' ? 'Atendimento personalizado' : 'Personalized Service'}
                                tituloPrimeiro={false}
                            />

                            <Card
                                color={style.colors.primary}
                                conteudo={language === 'pt-br' ? 'Valorizamos o relacionamento com nossos clientes e buscamos construir parcerias de longo prazo, trabalhando de forma colaborativa para entender suas necessidades e desafios' : 'We value our customer relationships and seek to build long-term partnerships, working collaboratively to understand your needs and challenges.'}
                                titulo={language === 'pt-br' ? 'Relacionamento de parceria' : 'Partnership Relationship'}
                                tituloPrimeiro={false}
                            />
                        </ContainerCard>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                "Com nosso Serviço de Sustentação N1 e N2, você terá a tranquilidade de saber que sua infraestrutura tecnológica está nas mãos de especialistas confiáveis." :
                                "With our N1 and N2 Maintenance Service, you will have peace of mind knowing your technological infrastructure is in the hands of reliable specialists."
                            }
                        </ConteudoText>
                        <Botao link='/contato' fullWidth={true} bgColor={style.colors.primary} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : 'Talk to us'} />
                    </>
                }
            />

            <Secao
                id={'gestao'}
                bgColor={style.colors.secondary}
                color={style.colors.darkGray}
                qtdIcone={1}
                icone={<Gcn />}
                titulo={language === 'pt-br' ? 'Gestão de Continuidade do Negócio' : 'Business Continuity Management'}
                conteudo={
                    <>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                "A gestão de continuidade do negócio (GCN) é essencial para garantir que sua organização esteja preparada para enfrentar interrupções inesperadas e crises. Nosso Serviço de GCN oferece a expertise necessária para planejar, implementar e manter estratégias eficazes de recuperação e continuidade." :
                                "Business continuity management (BCM) is essential to ensure your organization is prepared to face unexpected disruptions and crises. Our BCM Service offers the expertise necessary to plan, implement, and maintain effective recovery and continuity strategies."
                            }
                        </ConteudoText>
                        <ContainerCard>
                            <Card
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Realizamos uma avaliação detalhada dos riscos que podem afetar sua organização, identificando os pontos críticos e determinando os impactos potenciais. Com base nessa análise, desenvolvemos estratégias de mitigação adequadas' : 'We conduct a detailed assessment of the risks that can affect your organization, identifying critical points and determining potential impacts. Based on this analysis, we develop appropriate mitigation strategies.'}
                                titulo={language === 'pt-br' ? 'Avaliação de riscos e impactos' : 'Risk and Impact Assessment'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Criamos planos de continuidade personalizados, incluindo medidas preventivas, processos de recuperação, estratégias de comunicação e realocação de recursos, para garantir que sua organização esteja preparada para lidar com diversas situações de crise' : 'We create customized continuity plans, including preventive measures, recovery processes, communication strategies, and Continuity planning, strategies for communication, and resource reallocation to ensure your organization is prepared to handle various crisis situations.'}
                                titulo={language === 'pt-br' ? 'Planejamento de continuidade' : 'Continuity Planning'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Realizamos testes e exercícios práticos para validar os planos de continuidade do negócio, garantindo sua eficácia e identificando áreas de melhoria. Isso permite que sua organização esteja preparada e treinada para responder rapidamente a qualquer incidente' : 'We conduct tests and practical exercises to validate the business continuity plans, ensuring their effectiveness and identifying areas for improvement. This allows your organization to be prepared and trained to respond quickly to any incident.'} 
                                titulo={language === 'pt-br' ? 'Testes e exercícios' : 'Tests and Exercises'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Desenvolvemos estratégias de recuperação eficientes, incluindo backup e restauração de dados, para garantir que sua organização possa se recuperar rapidamente de interrupções e minimizar o impacto nos negócios' : 'We develop efficient recovery strategies, including data backup and restoration, to ensure your organization can recover quickly from disruptions and minimize business impacts.'}
                                titulo={language === 'pt-br' ? 'Recuperação de desastres' : 'Disaster Recovery'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.darkGray}
                                conteudo={ language === 'pt-br' ? 'Nossos serviços de GCN ajudam sua organização a estar em conformidade com regulamentações específicas, garantindo que você atenda a requisitos legais e regulatórios relacionados à continuidade do negócio' : 'Our BCM services help your organization comply with specific regulations, ensuring you meet legal and regulatory requirements related to business continuity.'}
                                titulo={language === 'pt-br' ? 'Conformidade regulatória' : 'Regulatory Compliance'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Acompanhamos e revisamos regularmente seus planos de continuidade para garantir que estejam alinhados com as mudanças organizacionais, tecnológicas e regulatórias, mantendo-os atualizados e eficazes' : 'We regularly monitor and review your continuity plans to ensure they align with organizational, technological, and regulatory changes, keeping them up-to-date and effective.'}
                                titulo={language === 'pt-br' ? 'Revisão e atualização contínuas' : 'Continuous Review and Update'}
                                tituloPrimeiro={false}
                            />
                        </ContainerCard>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                "Com nosso Serviço de Gestão de Continuidade do Negócio, sua organização estará preparada para enfrentar crises e interrupções, minimizando os impactos e garantindo a continuidade das operações." :
                                "With our Business Continuity Management Service, your organization will be prepared to face crises and disruptions, minimizing impacts, and ensuring operational continuity."
                            }
                        </ConteudoText>
                        <Botao link='/contato' fullWidth={true} bgColor={style.colors.darkGray} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : 'Talk to us'} />
                    </>
                }
            />

            <Secao
                id={'consultoria'}
                bgColor={style.colors.primary}
                color={style.colors.white}
                qtdIcone={1}
                icone={<Foguete />}
                titulo={language === 'pt-br' ? 'Consultoria de Tecnologia' : 'Technology Consulting'}
                conteudo={
                    <>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                "No cenário empresarial em constante evolução, a tecnologia desempenha um papel fundamental na diferenciação e no sucesso das organizações. Com nossa equipe de consultoria de tecnologia altamente qualificada, oferecemos soluções personalizadas para ajudar sua empresa a aproveitar ao máximo o potencial da tecnologia e impulsionar seu crescimento." :
                                "In the constantly evolving business environment, technology plays a fundamental role in the differentiation and success of organizations. With our highly qualified technology consulting team, we offer customized solutions to help your company make the most of technology and drive growth."
                            }
                        </ConteudoText>
                        <ContainerCard>
                            <Card
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Auxiliamos sua empresa a desenvolver uma estratégia de TI alinhada aos objetivos do negócio, identificando oportunidades de inovação e melhorias para impulsionar sua vantagem competitiva' : 'We help your company develop an IT strategy aligned with business objectives, identifying innovation and improvement opportunities to drive competitive advantage.'}
                                titulo={language === 'pt-br' ? 'Estratégia e planejamento de TI' : 'IT Strategy and Planning'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Orientamos sua organização na jornada de transformação digital, identificando as tecnologias e processos adequados para impulsionar a eficiência, melhorar a experiência do cliente e aumentar a agilidade operacional' : 'We guide your organization on the digital transformation journey, identifying suitable technologies and processes to drive efficiency, improve customer experience, and increase operational agility.'}
                                titulo={language === 'pt-br' ? 'Transformação digital' : 'Digital Transformation'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Realizamos avaliações detalhadas das necessidades da sua organização e recomendamos as melhores soluções tecnológicas que atendam aos seus requisitos, considerando aspectos como funcionalidade, escalabilidade, segurança e custo-benefício' : 'We conduct detailed assessments of your organization’s needs and recommend the best technology solutions to meet your requirements, considering aspects like functionality, scalability, security, and cost-effectiveness.'}
                                titulo={language === 'pt-br' ? 'Avaliação e seleção de tecnologia' : 'Technology Assessment and Selection'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Nossos consultores experientes fornecem liderança e orientação no gerenciamento de projetos de tecnologia, garantindo que eles sejam entregues dentro do prazo, do orçamento e dos requisitos estabelecidos' : 'Our experienced consultants provide leadership and guidance in technology project management, ensuring they are delivered within the stipulated time frame, budget, and requirements.'}
                                titulo={language === 'pt-br' ? 'Gerenciamento de projetos' : 'Project Management'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Analisamos seus processos de negócio existentes e identificamos oportunidades de automação e otimização para aumentar a eficiência, reduzir custos e melhorar a qualidade dos serviços' : 'We analyze your existing business processes and identify automation and optimization opportunities to increase efficiency, reduce costs, and improve service quality.'}
                                titulo={language === 'pt-br' ? 'Otimização de processos' : 'Process Optimization'}
                                tituloPrimeiro={false}
                            />
                            <Card
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Auxiliamos sua organização na implementação de estruturas de governança de TI eficientes, garantindo a conformidade com regulamentações e padrões do setor' : 'We help your organization implement efficient IT governance structures, ensuring compliance with industry regulations and standards.'}
                                titulo={language === 'pt-br' ? 'Governança e conformidade' : 'Governance and Compliance '}
                                tituloPrimeiro={false}
                            />
                        </ContainerCard>
                        <Titulo
                            bgColor={style.colors.primary}
                            color={style.colors.white}
                            titulo={language === 'pt-br' ? 'Principais cases e projetos em Consultoria de TI' : 'Key Cases and Projects in IT Consulting'}
                        />
                        <Container>
                            <Card
                                color={style.colors.white}
                                icone={<NFE />}
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Soluções de envio de cupom fiscal por e-mail' : 'Solutions for email fiscal coupon sending'}
                            />
                            <Card
                                color={style.colors.white}
                                icone={<Iot />}
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Desenvolvimento de projetos IoT' : 'IoT project development'}
                            />
                            <Card
                                color={style.colors.white}
                                icone={<Estrategia />}
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Estratégia e planejamento de Tecnologia' : 'Technology strategy and planning'}
                            />
                            <Card
                                color={style.colors.white}
                                icone={<Custo />}
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Redução de custos de Cloud' : 'Cloud cost reduction'}
                            />
                        </Container>
                        <ConteudoText>
                            {
                                language === 'pt-br' ?
                                "Com nosso Serviço de Consultoria de Tecnologia, você terá acesso a uma equipe especializada de consultores prontos para ajudá-lo a obter o máximo valor da tecnologia em sua organização. Trabalhamos de forma colaborativa, fornecendo orientação especializada e soluções adaptadas às suas necessidades específicas" :
                                "With our Technology Consulting Service, you will have access to a specialized team of consultants ready to help you maximize the value of technology in your organization. We work collaboratively, providing expert guidance and tailored solutions to your specific needs."
                            }
                            
                        </ConteudoText>
                        <Botao link='/contato' fullWidth={true} bgColor={style.colors.primary} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : 'Talk to us'} />
                    </>
                }
            />
            <Footer />
        </>
    );
}

export default ServicosPage;

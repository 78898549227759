import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import lgpdImage from '../../assets/images/photo/seguranca-informacao.jpg'

import { ReactComponent as Logo } from '../../assets/images/photo/cafs-colorido.svg';
import { ReactComponent as IconCafs } from '../../assets/images/icon/cafs-colorido.svg';
import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

// import { ReactComponent as Objetivo } from '../../assets/images/icon/objetivo.svg';
import { ReactComponent as Dados } from '../../assets/images/icon/dados.svg';
import { ReactComponent as TipoDados } from '../../assets/images/icon/onboarding.svg';
import { ReactComponent as Compartilhamento } from '../../assets/images/icon/portabilidade.svg';
import { ReactComponent as Responsabilidade } from '../../assets/images/icon/parceiros.svg';
import { ReactComponent as Info } from '../../assets/images/icon/info.svg';
import { ReactComponent as Seguranca } from '../../assets/images/icon/seguranca.svg';

import { ReactComponent as Objetivo } from '../../assets/images/icon/foguete.svg';
import Footer from "../../components/Footer";
import useLanguage from "../../state/hooks/setLingua";

const LgpdPage = () => {
  const { language } = useLanguage();

  return (
    <>
      <Funcbar backgroundColor={style.colors.primary} />
      <Banner
        img={lgpdImage}
        titulo={language === 'pt-br' ? ['LGPD'] : ['LGPD']}
        subtitulo={language === 'pt-br' ? ['Política de Privacidade'] : ['Privacy Policy']}
        corGradient='#2B2B2B00'
        colorida={false}
        logo={<Logo />}
      />
      <Secao
        bgColor={style.colors.white}
        color={style.colors.primary}
        titulo={language === 'pt-br' ? 'Objetivo' : 'Objective'}
        icone={<Objetivo />}
        qtdIcone={1}
        conteudo={
          <ConteudoText color={style.colors.primary}>
            {
              language === 'pt-br' ?
                "A presente Política de Privacidade tem como objetivo demonstrar o compromisso da CAFS Tecnologia com a proteção de dados pessoais, em conformidade com a Lei Geral de Proteção de Dados Pessoais (LGPD) - Lei nº 13.709/2018, aplicável no Brasil." :
                "This Privacy Policy aims to demonstrate CAFS Tecnologia's commitment to the protection of personal data, in compliance with the General Data Protection Law (LGPD) – Law No. 13,709/2018, applicable in Brazil."
            }
            <br></br>
            <br></br>
            {
              language === 'pt-br' ?
                "Esta política descreve como coletamos, tratamos e protegemos os dados pessoais dos nossos contatos empresariais, parceiros, fornecedores e clientes." :
                "This policy describes how we collect, handle, and protect the personal data of our business contacts, partners, suppliers, and customers."
            }
          </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.primary}
        color={style.colors.white}
        titulo={language === 'pt-br' ? 'Propósito do tratamento de dados' : 'Purpose of Data Processing'}
        icone={<Dados />}
        qtdIcone={1}
        conteudo={
          <ConteudoText color={style.colors.white}>
            {
              language === 'pt-br' ?
                "A CAFS Tecnologia realiza o tratamento de dados pessoais exclusivamente para fins relacionados às suas operações comerciais, incluindo:" :
                "CAFS Tecnologia processes personal data exclusively for purposes related to its business operations, including:"
            }
            <br></br>
            <br></br>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Execução de contratos' : 'Contract Execution'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Gerenciamento de relações comerciais com nossos clientes, parceiros e fornecedores' : 'Management of business relationships with our customers, partners, and suppliers'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Comunicações comerciais' : 'Commercial Communications'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Envio de comunicações relativas a serviços, produtos, oportunidades de negócios e eventos empresariais, que possam ser de interesse dos nossos contatos corporativos' : 'Sending communications related to services, products, business opportunities, and corporate events that may be of interest to our business contacts'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Cumprimento de obrigações legais' : 'Compliance with legal obligations'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Atendimento a solicitações de autoridades e cumprimento de requisitos regulatórios' : 'Responding to requests from authorities and meeting regulatory requirements'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Suporte ao cliente e melhorias nos serviços' : 'Customer support and service improvements'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Garantir a qualidade do atendimento e aprimorar a prestação dos nossos serviços' : 'Ensuring service quality and enhancing the delivery of our services'}
              />
            </ContainerCard>
          </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.darkGray}
        color={style.colors.white}
        titulo={language === 'pt-br' ? 'Tipo de dados e duração do tratamento' : 'Type of Data and Duration of Processing'}
        icone={<TipoDados />}
        qtdIcone={1}
        conteudo={
          <ConteudoText color={style.colors.white}>
            {
              language === 'pt-br' ?
                "Os dados pessoais tratados pela CAFS Tecnologia no contexto das relações B2B incluem:" :
                "The personal data processed by CAFS Tecnologia in the context of B2B relationships includes:"
            }
            <br></br>
            <br></br>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? '• Nome completo' : '• Full Name'}
                color={style.colors.white}
                conteudo=''
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? '• Cargo e função na empresa' : '• Position and role in the company'}
                color={style.colors.white}
                conteudo=''
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? '• Endereço de e-mail corporativo' : '• Corporate email address'}
                color={style.colors.white}
                conteudo=''
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? '• Telefone de contato' : '• Contact phone number'}
                color={style.colors.white}
                conteudo=''
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? '• Informações sobre a empresa (razão social, CNPJ, endereço)' : '• Company information (corporate name, tax ID, address)'}
                color={style.colors.white}
                conteudo=''
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? '• Histórico de comunicação e interação' : '• Communication and interaction history'}
                color={style.colors.white}
                conteudo=''
              />
            </ContainerCard>
            <br></br>
            <br></br>
            {
              language === 'pt-br' ?
                "Esses dados são coletados diretamente dos representantes das empresas com quem mantemos relações comerciais. O prazo de armazenamento desses dados será mantido pelo tempo necessário para atender às finalidades para as quais foram coletados ou conforme exigido por lei. Quando os dados não forem mais necessários ou forem solicitados para exclusão, serão devidamente eliminados." :
                "These data are collected directly from representatives of the companies with whom we maintain business relations. The storage period for these data will be maintained as long as necessary to meet the purposes for which they were collected or as required by law. When the data are no longer needed or are requested to be deleted, they will be duly eliminated."
            }
          </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.secondary}
        color={style.colors.darkGray}
        titulo={language === 'pt-br' ? 'Identidade e contato do controlador de dados' : 'Data Controller Identity and Contact'}
        icone={<IconCafs />}
        qtdIcone={1}
        conteudo={
          <ConteudoText>
            {
              language === 'pt-br' ? 'O controlador dos dados pessoais tratados é a CAFS Tecnologia, com sede na Praça dos Cravos, 10 - Barueri - SP - CEP: 06453-063, CNPJ 10.757.248/0001-55.' :
                'The controller of the processed personal data is CAFS Tecnologia, located at Praça dos Cravos, 10 - Barueri - SP - ZIP: 06453-063, Tax ID 10.757.248/0001-55.'
            }
            <br></br>
            <br></br>
            {
              language === 'pt-br' ? 'Para questões relacionadas à privacidade e ao tratamento de dados pessoais, o encarregado de proteção de dados (DPO) da CAFS Tecnologia pode ser contatado pelo e-mail: dpo@cafstecnologia.com.br ou telefone: 11.9.4496-2401.' :
                'For privacy and data processing inquiries, the CAFS Tecnologia Data Protection Officer (DPO) can be contacted by email: dpo@cafstecnologia.com.br or phone: +55 11 9 4496-2401.'
            }
          </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.darkGray}
        color={style.colors.white}
        titulo={language === 'pt-br' ? 'Compartilhamento de dados e motivos' : 'Data Sharing and Reasons'}
        icone={<Compartilhamento />}
        qtdIcone={1}
        conteudo={
          <ConteudoText>
            {
              language === 'pt-br' ?
                'A CAFS pode compartilhar dados pessoais com terceiros, conforme descrito abaixo:' :
                'CAFS may share personal data with third parties, as described below:'
            }
            <br></br>
            <br></br>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Prestadores de serviços' : 'Service Providers'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Empresas contratadas para auxiliar nas operações da CAFS Tecnologia, como provedores de serviços tecnológicos, soluções de CRM e marketing, ou consultorias empresariais' : 'Companies hired to assist in CAFS Tecnologia’s operations, such as technology service providers, CRM and marketing solutions, or business consulting firms.'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Parceiros de negócios' : 'Business Partners'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Quando necessário para a execução de contratos e iniciativas comerciais conjuntas' : 'When necessary for contract execution and joint business initiatives.'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Autoridades legais' : 'Legal Authorities'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Quando houver exigência legal ou regulatória, ou em resposta a ordens judiciais e processos administrativos' : 'When legally or regulatory required, or in response to court orders and administrative proceedings.'}
              />
            </ContainerCard>
            <br></br>
            <br></br>
            {
              language === 'pt-br' ?
                'Em todos os casos, exigimos que os terceiros que recebem dados pessoais sigam a legislação vigente e adotem medidas adequadas para garantir a segurança e confidencialidade dos dados compartilhados.' :
                'In all cases, we require third parties receiving personal data to comply with current legislation and adopt appropriate measures to ensure the security and confidentiality of shared data.'
            }
          </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.white}
        color={style.colors.darkGray}
        titulo={language === 'pt-br' ? 'Responsabilidades dos processadores de dados' : 'Responsibilities of Data Processors'}
        icone={<Responsabilidade />}
        qtdIcone={1}
        conteudo={
          <ConteudoText color={style.colors.darkGray}>
            {
              language === 'pt-br' ?
                "Sempre que utilizamos prestadores de serviços (operadores de dados) para o tratamento de dados pessoais em nosso nome, garantimos que esses operadores atuem de acordo com as instruções da CAFS Tecnologia e em conformidade com a LGPD." :
                "Whenever we use service providers (data processors) to process personal data on our behalf, we ensure that these processors act in accordance with CAFS Tecnologia’s instructions and comply with the LGPD."
            }
            <br></br>
            <br></br>
            {
              language === 'pt-br' ?
                "Todos os operadores são contratualmente obrigados a implementar medidas técnicas e organizacionais adequadas para proteger os dados pessoais." :
                "All processors are contractually obligated to implement appropriate technical and organizational measures to protect personal data."
            }
          </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.primary}
        color={style.colors.white}
        titulo={language === 'pt-br' ? 'Direitos dos titulares dos dados e como exercê-los' : 'Data Subjects’ Rights and How to Exercise Them'}
        icone={<Info />}
        qtdIcone={1}
        conteudo={
          <ConteudoText>
            {
              language === 'pt-br' ?
                'Todos os titulares de dados pessoais possuem os seguintes direitos, conforme previsto pela LGPD' :
                'All personal data subjects have the following rights, as provided by the LGPD'
            }
            <br></br>
            <br></br>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Confirmação e acesso' : 'Confirmation and Access'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Solicitar a confirmação da existência de tratamento de seus dados e obter acesso aos mesmos' : 'Request confirmation of the existence of data processing and access to such data.'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Correção' : 'Correction'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Solicitar a correção de dados pessoais incompletos, inexatos ou desatualizados' : 'Request correction of incomplete, inaccurate, or outdated personal data.'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Anonimização, bloqueio ou eliminação' : 'Anonymization, Blocking, or Deletion'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Solicitar a anonimização, bloqueio ou eliminação de dados considerados desnecessários ou tratados em desconformidade com a LGPD' : 'Request anonymization, blocking, or deletion of data considered unnecessary or processed in non-compliance with the LGPD.'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Portabilidade' : 'Portability'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Requerer a portabilidade dos dados a outro fornecedor de serviços ou produtos, conforme regulamentado pela Autoridade Nacional de Proteção de Dados (ANPD)' : 'Request data portability to another service or product provider, as regulated by the National Data Protection Authority (ANPD).'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Eliminação de dados' : 'Data Deletion'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Solicitar a eliminação dos dados pessoais tratados com base no consentimento, salvo nos casos em que o tratamento é necessário para o cumprimento de obrigações legais ou contratuais' : 'Request deletion of personal data processed based on consent, except in cases where processing is necessary for fulfilling legal or contractual obligations.'}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Revogação de consentimento' : 'Consent Withdrawal'}
                color={style.colors.white}
                conteudo={language === 'pt-br' ? 'Retirar o consentimento previamente fornecido, caso aplicável' : 'Withdraw previously given consent, if applicable.'}
              />
            </ContainerCard>
            <br></br>
            <br></br>
            {
              language === 'pt-br' ?
                'Para exercer esses direitos, entre em contato com o DPO através do e-mail: dpo@cafstecnologia.com.br' :
                'To exercise these rights, contact the DPO via email at: dpo@cafstecnologia.com.br'
            }
          </ConteudoText>
        }
      />

      <Secao
        bgColor={style.colors.white}
        color={style.colors.primary}
        titulo={language === 'pt-br' ? 'Segurança dos dados' : 'Data Security'}
        icone={<Seguranca />}
        qtdIcone={1}
        conteudo={
          <ConteudoText>
            {
              language === 'pt-br' ?
                'A CAFS Tecnologia adota medidas de segurança técnicas e administrativas apropriadas para proteger os dados pessoais contra acesso não autorizado, perda, alteração ou destruição' :
                'CAFS Tecnologia adopts appropriate technical and administrative security measures to protect personal data against unauthorized access, loss, alteration, or destruction.'
            }
            <br></br>
            <br></br>
            {
              language === 'pt-br' ?
                'Implementamos controles internos para garantir a conformidade com a LGPD e treinamos nossos colaboradores sobre boas práticas de proteção de dados' :
                'We have implemented internal controls to ensure compliance with the LGPD and train our employees on best data protection practices.'
            }
          </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.primary}
        color={style.colors.white}
        titulo={language === 'pt-br' ? 'Alterações a esta política de privacidade' : 'Changes to this Privacy Policy'}
        icone={<Info />}
        qtdIcone={1}
        conteudo={
          <ConteudoText color={style.colors.white}>
            {
              language === 'pt-br' ?
                "Esta política poderá ser atualizada para refletir mudanças na legislação ou nas práticas comerciais da CAFS Tecnologia. Recomendamos que os usuários verifiquem periodicamente esta página para se manterem informados sobre como protegemos os dados pessoais." :
                "This policy may be updated to reflect changes in legislation or CAFS Tecnologia's business practices. We recommend that users periodically check this page to stay informed about how we protect personal data."
            }
          </ConteudoText>
        }
      />
      <Footer />
    </>
  );
}

export default LgpdPage;

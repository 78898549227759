import React from "react";
import { style } from "../../../styles";
import Secao from "../../../components/Secao";
import Card from "../../../components/Card";
import ContainerCard from "../../../components/ContainerCard";

import { ReactComponent as IconServicos } from '../../../assets/images/icon/servicos.svg';
import { ReactComponent as Sustentacao } from '../../../assets/images/icon/sustentacao.svg';
import { ReactComponent as Gcn } from '../../../assets/images/icon/gcn.svg';
import { ReactComponent as Governanca } from '../../../assets/images/icon/gov.svg';
import { ReactComponent as Consult } from '../../../assets/images/icon/foguete.svg';

import Botao from "../../../components/Botao";
import useLanguage from '../../../state/hooks/setLingua';

const SectionServicos = () => {
    const { language } = useLanguage();

    return(
        <Secao
            id='servicos'
            titulo={language === 'pt-br' ? 'Serviços' : 'Services'}
            subtitulo={language === 'pt-br' ? 'Veja como podemos ajudar você' : 'See how we can help you'}
            bgColor={style.colors.primary}
            color={style.colors.secondary}
            conteudo={
                <>
                    <ContainerCard>
                        <Card
                            tamanho={2}
                            color={style.colors.secondary}
                            conteudo={language === 'pt-br' ? 'Mapear os processos da sua empresa é uma forma de entender como as atividades são realizadas' : "Mapping your company's processes is a way to understand how activities are carried out."}
                            icone={<Governanca />}
                            titulo={language === 'pt-br' ? 'Mapeamento de Processos e Gov. de TI' : 'Process Mapping and IT Governance'}
                            tituloPrimeiro={false}
                        />
                        <Card
                            tamanho={2}
                            color={style.colors.secondary}
                            conteudo={language === 'pt-br' ? 'Nosso serviço de Suporte e  Sustentação trará a tranquilidade de saber que sua infraestrutura tecnológica e suas aplicações estão nas mãos de especialistas confiáveis' : "Our Support and Maintenance service will bring peace of mind knowing that your technological infrastructure and applications are in the hands of reliable specialists."}
                            icone={<Sustentacao />}
                            titulo={language === 'pt-br' ? 'Suporte e Sustentação' : 'Support and Maintenance'}
                            tituloPrimeiro={false}
                        />
                        <Card
                            tamanho={2}
                            color={style.colors.secondary}
                            conteudo={language === 'pt-br' ? 'A gestão de continuidade do negócio (GCN) é essencial para garantir que sua organização esteja preparada para enfrentar interrupções inesperadas e crises' : 'Business continuity management (BCM) is essential to ensure your organization is prepared to face unexpected disruptions and crises.'}
                            icone={<Gcn />}
                            titulo={language === 'pt-br' ? 'Gestão de Continuidade do Negócio' : 'Business Continuity Management'} 
                            tituloPrimeiro={false}
                        />
                        <Card
                            tamanho={2}
                            color={style.colors.secondary}
                            conteudo={language === 'pt-br' ? 'Com nossa equipe de consultoria de tecnologia altamente qualificada, oferecemos soluções personalizadas para ajudar sua empresa a aproveitar ao máximo o potencial da tecnologia e impulsionar seu crescimento' : 'With our highly qualified technology consulting team, we offer customized solutions to help your company make the most of technology and drive growth.'}
                            icone={<Consult />}
                            titulo={language === 'pt-br' ? 'Consultoria de Tecnologia' : 'Technology Consulting'}
                            tituloPrimeiro={false}
                        />
                    </ContainerCard>
    
                    <Botao link='/servicos' fullWidth={true} bgColor={style.colors.primary} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Ver Mais' : 'See more'} />
                </>
            }
            icone={<IconServicos />}
            qtdIcone={1}
        />
    )
}

export default SectionServicos;
import React from "react";
import styled from "styled-components";
import { style } from "../../styles";
import brasil from '../../assets/images/photo/brasil.jpg';
import eua from '../../assets/images/photo/eua.jpg';
import setLanguage from '../../state/hooks/setLingua';

const Container = styled.div`
    width: 35px;
    height: 35px;
    position: fixed;
    top: 100px;
    right: 10px;
    z-index: 10000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    background-color: ${style.colors.white};
    border-radius: 1.5rem;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.7);
    transition: width 0.5s ease;
    
    &:hover {
        width: 120px;
        justify-content: space-around;
        border-radius: .3rem;
    }
`

const Bandeira = styled.img`
    width: 28px;
    height: 28px;
    border-radius: 50%;
`

const Nome = styled.p`
    font-size: 0;
    max-width: 0;
    opacity: 0;
    font-family: ${style.typography.fontFamily.light};
    white-space: nowrap;
    overflow: hidden;
    transition: font-size 0.5s ease, max-width 0.5s ease, opacity 0.5s ease;
    text-decoration: none;
    color: black;

    ${Container}:hover & {
        font-size: 14px;
        max-width: 80px;
        opacity: 1;
    }
`

const Lingua = () => {
    const { language, toggleLanguage } = setLanguage();

    return (
        <Container
            onClick={toggleLanguage}
        >
            <Bandeira src={language !== 'pt-br' ? brasil : eua} />
            <Nome>{language === 'pt-br' ? 'eng' : 'pt-br'}</Nome>
        </Container>
    );
}

export default Lingua;

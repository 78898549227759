import React from "react";
import styled from "styled-components";
import { style } from "../../styles";
import useLenguage from "../../state/hooks/setLingua";

const ContainerInput = styled.div`
    width: 40%;
    height: 60px;

    @media screen and (max-width: ${style.screens.medium}) {
        margin-bottom: 3rem;
        width: 100%;
    }
`;

const Label = styled.label`
    width: 100%;
    font-size: ${style.typography.fontSize.p};
    font-family: ${style.typography.fontFamily.medium};
    color: ${props => props.error ? '#DC1A1A' : style.colors.darkGray};
    
    @media screen and (max-width: ${style.screens.medium}) {
        width: 39%;
    }
`;

const SelectBoxWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  font-size: ${style.typography.fontSize.p};
  font-family: ${style.typography.fontFamily.light};
`;

const SelectBoxCurrent = styled.div`
  position: relative;
  cursor: pointer;
  outline: none;
  padding: 2px;


  &:focus + ul {
    opacity: 1;
    animation-name: none;
  }

  &:focus + ul .select-box__option {
    cursor: pointer;
  }

  &:focus .select-box__icon {
    transform: translateY(-50%) rotate(180deg);
  }
`;

const SelectBoxList = styled.ul`
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  animation-name: HideList;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);

  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }
`;

const SelectBoxValue = styled.div`
  display: flex;
`;

const SelectBoxInput = styled.input`
  display: none;

  &:checked + .select-box__input-text {
    display: block;
  }
`;

const SelectBoxInputText = styled.p`
  display: none;
  width: 100%;
  margin: 0;
  padding: .2rem;
  background-color: #fff;
`;

const SelectBoxOption = styled.label`
  display: block;
  padding: 15px;
  background-color: #fff;

  &:hover,
  &:focus {
    color: #546c84;
    background-color: #fbfbfb;
  }
`;

const SelectBoxIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  opacity: 0.3;
  transition: 0.2s ease;
`;

const Select = ({ selectedOption, handleInputChange }) => {
  const { language } = useLenguage();

    return (
        <ContainerInput>
            <Label>{language === 'pt-br' ? 'Selecione um assunto' : 'Select a subject'}</Label>
            <SelectBoxWrapper>
                <SelectBoxCurrent className="select-box__current" tabIndex="1">
                    <SelectBoxValue>
                        <SelectBoxInput
                            className="select-box__input"
                            type="radio"
                            id="0"
                            value="1"
                            checked={selectedOption === '0'}
                            onChange={handleInputChange}
                        />
                        <SelectBoxInputText className="select-box__input-text">{language === 'pt-br' ? 'Cotação' : 'Quote'}</SelectBoxInputText>
                    </SelectBoxValue>
                    <SelectBoxValue className="select-box__value">
                        <SelectBoxInput
                            className="select-box__input"
                            type="radio"
                            id="1"
                            value="2"
                            checked={selectedOption === '1'}
                            onChange={handleInputChange}
                        />
                        <SelectBoxInputText className="select-box__input-text">{language === 'pt-br' ? 'Dúvidas' : 'Questions'}</SelectBoxInputText>
                    </SelectBoxValue>
                    <SelectBoxValue className="select-box__value">
                        <SelectBoxInput
                            className="select-box__input"
                            type="radio"
                            id="2"
                            value="3"
                            checked={selectedOption === '2'}
                            onChange={handleInputChange}
                        />
                        <SelectBoxInputText className="select-box__input-text">{language === 'pt-br' ? 'Suporte' : 'Support'}</SelectBoxInputText>
                    </SelectBoxValue>
                    <SelectBoxValue className="select-box__value">
                        <SelectBoxInput
                            className="select-box__input"
                            type="radio"
                            id="3"
                            value="4"
                            checked={selectedOption === '3'}
                            onChange={handleInputChange}
                        />
                        <SelectBoxInputText className="select-box__input-text">{language === 'pt-br' ? 'Parcerias' : 'Partnerships'}</SelectBoxInputText>
                    </SelectBoxValue>
                    <SelectBoxIcon className="select-box__icon" src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true" />
                </SelectBoxCurrent>
                <SelectBoxList className="select-box__list">
                    <li>
                        <SelectBoxOption className="select-box__option" htmlFor="0" aria-hidden="aria-hidden">{language === 'pt-br' ? 'Cotação' : 'Quote'}</SelectBoxOption>
                    </li>
                    <li>
                        <SelectBoxOption className="select-box__option" htmlFor="1" aria-hidden="aria-hidden">{language === 'pt-br' ? 'Dúvidas' : 'Questions'}</SelectBoxOption>
                    </li>
                    <li>
                        <SelectBoxOption className="select-box__option" htmlFor="2" aria-hidden="aria-hidden">{language === 'pt-br' ? 'Suporte' : 'Support'}</SelectBoxOption>
                    </li>
                    <li>
                        <SelectBoxOption className="select-box__option" htmlFor="3" aria-hidden="aria-hidden">{language === 'pt-br' ? 'Parcerias' : 'Partnerships'}</SelectBoxOption>
                    </li>
                </SelectBoxList>
            </SelectBoxWrapper>
        </ContainerInput>
    )
}

export default Select;
import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import sobreImage from '../../assets/images/photo/sobre.jpg'

import { ReactComponent as Logo } from '../../assets/images/photo/cafs-colorido.svg';
import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

// import { ReactComponent as Objetivo } from '../../assets/images/icon/objetivo.svg';
import { ReactComponent as Missao } from '../../assets/images/icon/missao.svg';
import { ReactComponent as Visao } from '../../assets/images/icon/visao.svg';
import { ReactComponent as Valores } from '../../assets/images/icon/valores.svg';
import Footer from "../../components/Footer";
import useLanguage from "../../state/hooks/setLingua";

const SobrePage = () => {
  const { language } = useLanguage();

  return (
    <>
      <Funcbar backgroundColor={style.colors.primary} />
      <Banner
        img={sobreImage}
        titulo={language === 'pt-br' ? ['Sobre'] : ['About Us']}
        subtitulo={language === 'pt-br' ? ['Nosso legado é construído sobre valores sólidos,', 'visão audaciosa e compromisso com a qualidade'] : ['Our legacy is built on solid values,', 'bold vision, and a commitment to quality.']}
        corGradient='#2B2B2B00'
        colorida={false}
        logo={<Logo />}
      />
      <Secao 
        bgColor={style.colors.secondary}
        conteudo={
            <ConteudoText color={style.colors.darkGray}>
              {
                language === 'pt-br' ?
                "Bem-vindos à nossa empresa, um lugar onde a essência familiar molda nossa cultura empresarial. Fundada em 2009, assim como toda família, compartilhamos valores, princípios e virtudes que nos tornam diferentes das demais empresas" :
                "Welcome to our company, a place where the essence of family shapes our corporate culture. Founded in 2009, like any family, we share values, principles, and virtues that make us different from other companies."
              }
            </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.white}
        color={style.colors.primary}
        titulo={language === 'pt-br' ? 'Objetivo' : 'Objective'}
        icone={<Logo />}
        qtdIcone={1}
        conteudo={
            <ConteudoText color={style.colors.primary}>
              {
                language === 'pt-br' ?
                "Nosso objetivo é apoiar empresas e pessoas, independentemente do segmento, orçamento ou complexidade das soluções necessárias. Nosso foco principal é fazer a tecnologia trabalhar em benefício de nossos clientes, tornando-a uma aliada em suas jornadas, em vez de um obstáculo a ser superado." :
                "Our objective is to support companies and individuals regardless of the segment, budget, or complexity of the required solutions. Our focus is to make technology work in favor of our clients, turning it into an ally in their journeys rather than an obstacle to overcome."
              } 
                <br></br>
                <br></br>
                {
                  language === 'pt-br' ?
                  "Buscamos oferecer uma experiência transparente e sem complicações, permitindo que você se concentre totalmente em seu core business, sem perder tempo com problemas técnicos. Nosso maior orgulho é ter você como nosso cliente e parceiro nessa jornada." :
                  "We strive to offer a transparent and hassle-free experience, allowing you to focus entirely on your core business without wasting time on technical problems. Our greatest pride is having you as our client and partner on this journey."
                }
            </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.primary}
        color={style.colors.white}
        titulo={language === 'pt-br' ? 'Missão' : 'Mission'}
        icone={<Missao />}
        qtdIcone={1}
        conteudo={
            <ConteudoText color={style.colors.white}>
              {
                language === 'pt-br' ?
                "Na CaFs Tecnologia, estamos comprometidos em impulsionar o sucesso de nossos clientes por meio de soluções tecnológicas inovadoras e de qualidade. Nosso objetivo é ser a parceira estratégica confiável para empresas de todos os portes, proporcionando transformação digital e criando impacto positivo em suas operações e resultados. Nós entendemos que cada empresa é única, com desafios e necessidades específicas." :
                "At CaFs Tecnologia, we are committed to driving our clients' success through innovative and quality technological solutions. Our goal is to be the trusted strategic partner for companies of all sizes, providing digital transformation and creating a positive impact on their operations and results. We understand that every company is unique, with specific challenges and needs."
              }
                <br></br>
                <br></br>
                {
                  language === 'pt-br' ?
                  "É por isso que nos dedicamos a oferecer serviços de tecnologia personalizados, alinhados com os objetivos e cultura de negócios de nossos clientes. Buscamos entender profundamente suas demandas e fornecer soluções que tragam eficiência, produtividade e diferenciação no mercado." :
                  "That is why we are dedicated to offering personalized technology services aligned with our clients' business objectives and culture. We strive to deeply understand your demands and provide solutions that bring efficiency, productivity, and market differentiation."
                }
            </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.darkGray}
        color={style.colors.white}
        titulo={language === 'pt-br' ? 'Visão' : 'Vision'}
        icone={<Visao />}
        qtdIcone={1}
        conteudo={
            <ConteudoText color={style.colors.white}>
              {
                language === 'pt-br' ?
                "Na CaFs Tecnologia, aspiramos ser líderes no mercado de tecnologia, impulsionando a transformação digital e sendo referência em excelência e inovação. Nossa visão é ser reconhecida como a parceira estratégica de longo prazo para nossos clientes, proporcionando soluções tecnológicas que os diferenciem em seus setores e impulsionem seu sucesso contínuo." :
                "At CaFs Tecnologia, we aspire to be leaders in the technology market, driving digital transformation, and being a reference in excellence and innovation. Our vision is to be recognized as the long-term strategic partner for our clients, providing technological solutions that differentiate them in their sectors and drive their continuous success."
              }
            </ConteudoText>
        }
      />
      <Secao
        bgColor={style.colors.secondary}
        color={style.colors.darkGray}
        titulo={language === 'pt-br' ? 'Valores' : 'Values'}
        icone={<Valores />}
        qtdIcone={1}
        conteudo={
            <ContainerCard>
                <Card 
                    tituloPrimeiro={false}
                    titulo={language === 'pt-br' ? 'Valorização das Pessoas' : 'Valuing People'}
                    color={style.colors.darkGray}
                    conteudo={language === 'pt-br' ? 'Acreditamos no potencial das pessoas e valorizamos cada membro da nossa equipe' : 'We believe in people’s potential and value each member of our team.'}
                />
                <Card 
                    tituloPrimeiro={false}
                    titulo={language === 'pt-br' ? 'Paixão pelo que Fazemos' : 'Passion for What We Do'}
                    color={style.colors.darkGray}
                    conteudo={language === 'pt-br' ? 'Amamos o que fazemos e estamos comprometidos em oferecer soluções tecnológicas excepcionais' : 'We love what we do and are committed to offering exceptional technological solutions.'}
                />
                <Card 
                    tituloPrimeiro={false}
                    titulo={language === 'pt-br' ? 'Trabalho em Equipe' : 'Teamwork'}
                    color={style.colors.darkGray}
                    conteudo={language === 'pt-br' ? 'Encaramos os desafios juntos, promovendo a colaboração e a sinergia entre os membros da equipe' : 'We face challenges together, promoting collaboration and synergy among team members.'}
                />
                <Card 
                    tituloPrimeiro={false}
                    titulo={language === 'pt-br' ? 'Respeito em todas as Relações' : 'Respect in All Relationships'}
                    color={style.colors.darkGray}
                    conteudo={language === 'pt-br' ? 'Não toleramos a falta de respeito. Mantemos relacionamentos baseados na consideração mútua' : 'We do not tolerate disrespect. We maintain relationships based on mutual consideration.'}
                />
                <Card 
                    tituloPrimeiro={false}
                    titulo={language === 'pt-br' ? 'Aprendizado Contínuo' : 'Continuous Learning'}
                    color={style.colors.darkGray}
                    conteudo={language === 'pt-br' ? 'Reconhecemos que aprendemos com os erros e buscamos constantemente melhorar' : 'We recognize that we learn from mistakes and constantly seek to improve'}
                />
                <Card 
                    tituloPrimeiro={false}
                    titulo={language === 'pt-br' ? 'Atualização e Inovação' : 'Updating and Innovation'}
                    color={style.colors.darkGray}
                    conteudo={language === 'pt-br' ? 'Mantemo-nos atualizados sobre as últimas tendências e avanços tecnológicos' : 'We stay updated on the latest trends and technological advances'}
                />
                <Card 
                    tituloPrimeiro={false}
                    titulo={language === 'pt-br' ? 'Integridade e Honestidade' : "Integrity and Honesty"}
                    color={style.colors.darkGray}
                    conteudo={language === 'pt-br' ? 'Atuamos com integridade em todas as nossas ações, tratando nossos clientes, colaboradores e parceiros com honestidade e transparência' : 'We act with integrity in all our actions, treating our clients, employees, and partners with honesty and transparency'}
                />
            </ContainerCard>
        }
      />
      <Footer />
    </>
  );
}

export default SobrePage;

import { useRecoilState } from 'recoil';
import { language as lingua } from '../atom';

const useLenguage = () => {
  const [language, setLanguage] = useRecoilState(lingua);

  const toggleLanguage = () => {
    const newLenguage = language === 'pt-br' ? 'eng' : 'pt-br';
    setLanguage(newLenguage);
  };

  return { language, toggleLanguage };
};

export default useLenguage;
import React, { useState } from 'react';
import { ReactComponent as Button } from '../../assets/images/icon/download.svg';
import useLanguage from '../../state/hooks/setLingua';
import styled from 'styled-components';
import { style } from '../../styles';

const IconContainer = styled.div`
  width: 20%;
  height: 36px;
  text-align: center;
`;

const IconButton = styled.button`
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 72px;
  height: 36px;
  border-radius: 30px;
  overflow: hidden;
  background: transparent;
  border: none;
  padding: 0;
  transition: all 0.45s ease;
  outline: none;

  &:hover::before {
    box-shadow: inset 0 0 0 36px ${style.colors.nexus};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.45s ease;
    border-radius: 30px;
    box-shadow: inset 0 0 0 1px ${style.colors.nexus};
  }
`;

const StyledHomeIcon = styled(Button)`
  width: 36px;
  height: 36px;
  color: #ffffff;
  color: ${style.colors.nexus};
`;



const DownloadJsonButton = ({ data }) => {
    const { language } = useLanguage()

    const downloadFile = (content, filename, type) => {
        const blob = new Blob([content], { type });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const convertToCsv = (data) => {
        if (data.length === 0) return '';

        const headers = Object.keys(data[0]);
        const csvRows = [
            headers.join(','),
            ...data.map(row =>
                headers.map(header => JSON.stringify(row[header], (key, value) => value ?? '')).join(',')
            )
        ];
        return csvRows.join('\n');
    };

    const downloadFiles = () => {
        if (!data || data.length === 0) {
            const mensagem = language === 'pt-br' ? 'Não há dados para download' : 'There is no data to download';
            alert(mensagem);
            return;
        }

        downloadFile(JSON.stringify(data, null, 2), 'dados-participantes-registradoras.json', 'application/json');

        const csvContent = convertToCsv(data);
        downloadFile(csvContent, 'dados-participantes-registradoras.csv', 'text/csv');
    };

    return (
        <IconContainer onClick={downloadFiles}>
            <IconButton title={language === 'pt-br' ? 'Download dados' : 'Download data'}>
                <StyledHomeIcon />
            </IconButton>
        </IconContainer>
    );
};

export default DownloadJsonButton;

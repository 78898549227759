import React from "react";
import styled from "styled-components";
import Titulo from "../Titulos";
import { style } from "../../styles";

const Container = styled.div`
    width: 100%;
    padding: 10vh 15%;
    margin: 0px;
    box-sizing: border-box;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};

    @media screen and (max-width: ${style.screens.large}) {
        padding: 10vh 10%;
    }

    @media screen and (max-width: ${style.screens.medium}) {
        padding: 10vh 5%;
    }
`

const Secao = ({ id, conteudo, titulo, subtitulo, bgColor, color, icone, qtdIcone }) => (
    <Container id={id} bgColor={bgColor} color={color}>
        {
            titulo ?
            <Titulo
                titulo={titulo}
                subtitulo={subtitulo}
                bgColor={bgColor}
                color={color}
                icone={icone}
                qtdIcone={qtdIcone}
            /> : null
        }
        {conteudo}
    </Container>
);

export default Secao;
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as IconeLogo } from '../../assets/images/icon/cafs-colorido.svg';
import { style } from '../../styles';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import useLanguage from '../../state/hooks/setLingua';
import { ReactComponent as Telefone } from '../../assets/images/icon/telefone.svg';
import { ReactComponent as Email } from '../../assets/images/icon/email.svg';
import { ReactComponent as Local } from '../../assets/images/icon/local.svg';
import { ReactComponent as WhatsApp } from '../../assets/images/icon/whatsapp.svg';

const Container = styled.footer`
    display: flex;
    flex-flow: row wrap;
    padding: 10vh 20%;
    padding-bottom: 1rem;
    color: ${style.colors.secondary};
    background-color: ${style.colors.darkGray};

    @media screen and (max-width: ${style.screens.medium}) {
        flex-direction: column;
        padding: 10vh 5%;
    }
`;

const ContainerLogo = styled.div`
    width: 20%;
    height: auto;
`

const Logo = styled.div`
    width: 80px;
    height: 80px;
`

const ContainerNav = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    @media screen and (max-width: ${style.screens.medium}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`

const Lista = styled.ul`
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    list-style: none;

    @media screen and (max-width: ${style.screens.medium}) {
        margin-top: 20px;
    }
`

const Item = styled.li`
    font-family: ${style.typography.fontFamily.regular};
    font-weight: 100;
    margin: 5px 0;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;
    color: ${style.colors.secondary};

    &:hover {
        color: ${style.colors.white};
    }
`

const Titulo = styled.h4`
    font-family: ${style.typography.fontFamily.bold};
    font-size: ${style.typography.fontSize.h4};
    color: ${style.colors.white};
    font-weight: 100;
`

const Copyright = styled.h3`
    font-family: ${style.typography.fontFamily.light};
    font-size: ${style.typography.fontSize.p};
    font-weight: 100;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
`

const TituoContato = styled.h3`
    font-family: ${style.typography.fontFamily.light};
    font-size: 1.2rem;
    font-weight: 100;
    width: 100%;
    text-align: start;
    margin-top: 5rem;
    
    @media screen and (max-width: ${style.screens.medium}) {
        text-align: center;
    }
`

const Contato = styled.h3`
    font-family: ${style.typography.fontFamily.light};
    font-size: ${style.typography.fontSize.p};
    font-weight: 100;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
`

const Info = styled.div`
    display: flex;
    width: 32%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: ${style.screens.medium}) {
        width: 100%;
        margin-top: 1rem;
    }
`

const IconeInfo = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ConteudoInfo = styled.p`
    width: 70%;
    margin-left: 1rem;
    font-family: ${style.typography.fontFamily.regular};
    font-size: 14px;
`

const WhatsAppButton = styled.a`
    width: 35px;
    height: 35px;
    position: fixed;
    top: 145px;
    right: 10px;
    z-index: 10000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    background-color: ${style.colors.white};
    border-radius: 1.5rem;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.7);
    transition: width 0.5s ease;
    color: #25D366;
    text-decoration: none;

    & svg {
        width: auto;
        height: 26px;
    }

    &:hover {
        width: 120px;
        justify-content: space-around;
        border-radius: .3rem;
    }

    &:hover p {
        font-size: 14px;
        max-width: 80px;
        opacity: 1;
    }
`

const WhatsAppText = styled.p`
    font-size: 0;
    max-width: 0;
    opacity: 0;
    font-family: ${style.typography.fontFamily.light};
    white-space: nowrap;
    overflow: hidden;
    transition: font-size 0.5s ease, max-width 0.5s ease, opacity 0.5s ease;
    text-decoration: none;
    color: black;
`

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const { language } = useLanguage();

    return (

        <Container>
            <WhatsAppButton href="https://api.whatsapp.com/send?phone=5511944962401" target="_blank">
                <WhatsApp />
                <WhatsAppText>WhatsApp</WhatsAppText>
            </WhatsAppButton>
            <ContainerLogo>
                <Logo>
                    <IconeLogo />
                </Logo>
            </ContainerLogo>
            <ContainerNav>
                <Lista>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/'>
                        <Item>Home</Item>
                    </Link>
                    <HashLink style={{ textDecoration: 'none' }} smooth to='/#produtos'>
                        <Item>{language === 'pt-br' ? "Produtos" : "Products"}</Item>
                    </HashLink>
                    <HashLink style={{ textDecoration: 'none' }} smooth to='/#servicos'>
                        <Item>{language === 'pt-br' ? "Serviços" : "Services"}</Item>
                    </HashLink>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/sobre'>
                        <Item>{language === 'pt-br' ? "Sobre" : "About"}</Item>
                    </Link>
                    <HashLink style={{ textDecoration: 'none' }} smooth to='/#parceiros'>
                        <Item>{language === 'pt-br' ? "Parceiros" : "Partners"}</Item>
                    </HashLink>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/contato'>
                        <Item>{language === 'pt-br' ? "Contato" : "Contact"}</Item>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/lgpd'>
                        <Item>LGPD</Item>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/seguranca-informacao'>
                        <Item>{language === 'pt-br' ? "Segurança da Informação" : "Information Security"}</Item>
                    </Link>
                </Lista>
                <Lista>
                    <Titulo>{language === 'pt-br' ? "Produtos" : "Products"}</Titulo>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/command-center'>
                        <Item>Command Center</Item>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/sensebot'>
                        <Item>Sensebot</Item>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/rad'>
                        <Item>RAD</Item>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to='/nexus'>
                        <Item>Nexus</Item>
                    </Link>
                </Lista>
                <Lista>
                    <Titulo>{language === 'pt-br' ? "Serviços" : "Services"}</Titulo>
                    <HashLink style={{ textDecoration: 'none' }} smooth to='/servicos#mapeamento'>
                        <Item>{language === 'pt-br' ? "Mapeamento de Processos e Gov. de TI" : "Process Mapping and IT Governance"}</Item>
                    </HashLink>
                    <HashLink style={{ textDecoration: 'none' }} smooth to='/servicos#suporte'>
                        <Item>{language === 'pt-br' ? "Suporte e Sustentação" : "Support and Maintenance"}</Item>
                    </HashLink>
                    <HashLink style={{ textDecoration: 'none' }} smooth to='/servicos#gestao'>
                        <Item>{language === 'pt-br' ? "Gestão de Continuidade do Negócio" : "Business Continuity Management"}</Item>
                    </HashLink>
                    <HashLink style={{ textDecoration: 'none' }} smooth to='/servicos#consultoria'>
                        <Item>{language === 'pt-br' ? "Consultoria de Tecnologia" : "Technology Consulting"}</Item>
                    </HashLink>
                </Lista>
            </ContainerNav>
            <TituoContato>Contatos</TituoContato>
            <Contato>
                <Info>
                    <IconeInfo>
                        <Telefone />
                    </IconeInfo>
                    <ConteudoInfo>(11) 9 4496-2401</ConteudoInfo>
                </Info>
                <Info>
                    <IconeInfo>
                        <Email />
                    </IconeInfo>
                    <ConteudoInfo>info@cafs.com.br</ConteudoInfo>
                </Info>
                <Info>
                    <IconeInfo>
                        <Local />
                    </IconeInfo>
                    <ConteudoInfo>Pr. dos Cravos, 10 - Alphaville Comercial, Barueri - SP, 06453-063</ConteudoInfo>
                </Info>
            </Contato>
            <Copyright>Copyright © 2024 CAFS Tecnologia - {language === 'pt-br' ? "Todos os direitos reservados" : "All rights reserved"}</Copyright>
        </Container>
    )
};

export default Footer;

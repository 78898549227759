import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import commandCenterImage from '../../assets/images/photo/commandCenter.jpg'
import { ReactComponent as Logo } from '../../assets/images/icon/commandColorido.svg';
import { ReactComponent as IconCafs } from '../../assets/images/icon/cafs-colorido.svg';
import { ReactComponent as Foguete } from '../../assets/images/icon/foguete.svg';
import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

import { ReactComponent as Monitoramento } from '../../assets/images/icon/monitor.svg';
import { ReactComponent as Incidente } from '../../assets/images/icon/alert.svg';
import { ReactComponent as Desempenho } from '../../assets/images/icon/solucao.svg';
import { ReactComponent as Analise } from '../../assets/images/icon/analise-dados.svg';
import { ReactComponent as Metrica } from '../../assets/images/icon/negocios.svg';
import Botao from "../../components/Botao";
import Footer from "../../components/Footer";

import useLanguage from "../../state/hooks/setLingua";

const CommandCenterPage = () => {
  const { language } = useLanguage();

  return (
    <>
      <Funcbar backgroundColor={style.colors.commandCenter} />
      <Banner
        img={commandCenterImage}
        titulo={['Command Center']}
        subtitulo={language === 'pt-br' ? ['O preço da excelência é a', 'eterna vigilância'] : ['The price of excellence', 'is eternal vigilance']}
        corGradient='#2B2B2B00'
        colorida={false}
        logo={<Logo />}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Descrição do serviço' : 'Service Description'}
        bgColor={style.colors.secondary}
        color={style.colors.commandCenter}
        conteudo={
          <>
            <ConteudoText color='#000'>
              {
                language === 'pt-br' ?
                  'No mundo da tecnologia em constante evolução, é crucial para as empresas terem uma visão completa e detalhada de seus sistemas, aplicações e métricas de negócio. É aí que entra o nosso serviço de Command Center, vamos além do trabalho de um NOC, ou de monitoração e observabilidade.' :
                  'In the constantly evolving technology world, it is crucial for companies to have a complete and detailed view of their systems, applications, and business metrics. Our Command Center service goes beyond the work of a NOC or monitoring and observability.'
              }
              <br></br>
              <br></br>
              {
                language === 'pt-br' ?
                  'Nós fornecemos serviços e soluções avançadas e abrangentes para garantir que você tenha controle total sobre sua operação garantindo o desempenho, a disponibilidade e a confiabilidade de todo seu ambiente. Com nossa abordagem especializada, combinamos ferramentas avançadas de monitoramento e observabilidade, análise de dados e inteligência artificial para fornecer insights valiosos em tempo real.' :
                  'We provide advanced and comprehensive services and solutions to ensure you have total control over your operation, ensuring performance, availability, and reliability of your entire environment. With our specialized approach, we combine advanced monitoring and observability tools, data analysis, and artificial intelligence to provide valuable real-time insights.'
              }
              <br></br>
              <br></br>
              {
                language === 'pt-br' ?
                  'Isso permite que você identifique e resolva proativamente incidentes, otimize o desempenho e melhore a experiência do seu cliente. Nossa equipe altamente qualificada e experiente trabalhará em estreita colaboração com você para personalizar nossas soluções de monitoração e observabilidade de acordo com suas necessidades específicas.' :
                  'This allows you to proactively identify and resolve incidents, optimize performance, and improve your customer experience. Our highly qualified and experienced team will work closely with you to customize our monitoring and observability solutions according to your specific needs.'
              }
              <br></br>
              <br></br>
              {
                language === 'pt-br' ?
                  'Com o nosso serviço, você terá total confiança de que seu ambiente tecnológico está sendo monitorado de forma abrangente e eficaz.' :
                  'With our service, you can be confident that your technological environment is being monitored comprehensively and effectively. '
              }
            </ConteudoText>
          </>
        }
        icone={<IconCafs />}
        qtdIcone={1}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Principais benefícios' : 'Main Benefits'}
        bgColor={style.colors.commandCenter}
        color={style.colors.darkGray}
        conteudo={
          <>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Monitoramento abrangente' : 'Comprehensive Monitoring'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Tenha uma visão holística de todos os aspectos críticos dos seus sistemas, incluindo desempenho, disponibilidade e utilização de recursos' : 'Have a holistic view of all critical aspects of your systems, including performance, availability, and resource utilization.'}
                icone={<Monitoramento />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Detecção e solução proativa de incidentes' : 'Proactive Incident Detection and Resolution'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Tenha uma visão holística de todos os aspectos críticos dos seus sistemas, incluindo desempenho, disponibilidade e utilização de recursos' : 'Have a holistic view of all critical aspects of your systems, including performance, availability, and resource utilization.'}
                icone={<Incidente />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Otimização de desempenho' : 'Performance Optimization'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Otimize a performance dos seus sistemas, identificando gargalos e áreas de melhoria para garantir a máxima eficiência' : `Optimize your systems' performance by identifying bottlenecks and areas for improvement to ensure maximum efficiency.`}
                icone={<Desempenho />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Análise avançada de dados' : 'Advanced Data Analysis'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Aproveite as capacidades de análise de dados e inteligência artificial para extrair insights valiosos e tomar decisões com base em dados e fatos' : 'Leverage data analysis and artificial intelligence capabilities to extract valuable insights and make data-driven decisions.'}
                icone={<Analise />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Métricas de negócio' : 'Business Metrics'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Monitoramos as métricas de negócios da sua empresa, apoiando a área de negócio, possibilitando ações rápidas garantindo a satisfação do cliente' : `We monitor your company's business metrics, supporting the business area and enabling quick actions to ensure customer satisfaction.`}
                icone={<Metrica />}
              />
            </ContainerCard>
            <Botao link='/contato' fullWidth={true} bgColor={style.colors.commandCenter} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : 'Talk to us'} />
          </>
        }
        icone={<Foguete />}
        qtdIcone={1}
      />
      <Footer />
    </>
  );
}

export default CommandCenterPage;

import React from "react";
import { style } from "../../../styles";
import Secao from "../../../components/Secao";
import Clientes from "../../../components/Clientes";
import useLanguage from "../../../state/hooks/setLingua";

const SectionClientes = () => {
    const { language } = useLanguage();

    return(
        <Secao
            titulo={language === 'pt-br' ? 'Depoimentos de Nossos Clientes' : 'Testimonials from Our Clients'}
            bgColor={style.colors.secondary}
            color={style.colors.darkGray}
            conteudo={<Clientes />}
            icone={''}
        />
    )
}

export default SectionClientes;
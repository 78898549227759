import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import lgpdImage from '../../assets/images/photo/seguranca-informacao.jpg'

import { ReactComponent as Logo } from '../../assets/images/photo/cafs-colorido.svg';
import { ReactComponent as IconCafs } from '../../assets/images/icon/cafs-colorido.svg';
import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

import { ReactComponent as Dados } from '../../assets/images/icon/estrategia.svg';
import { ReactComponent as Introducao } from '../../assets/images/icon/segment.svg';
import { ReactComponent as Penalidade } from '../../assets/images/icon/penalidade.svg';
import { ReactComponent as Canal } from '../../assets/images/icon/canal.svg';

import { ReactComponent as Objetivo } from '../../assets/images/icon/foguete.svg';
import Footer from "../../components/Footer";
import useLanguage from "../../state/hooks/setLingua";

const SegurancaInfoPage = () => {
    const { language } = useLanguage();

    return (
        <>
            <Funcbar backgroundColor={style.colors.primary} />
            <Banner
                img={lgpdImage}
                titulo={language === 'pt-br' ? ['Segurança da Informação'] : ['Information Security']}
                subtitulo={language === 'pt-br' ? ['Política Pública de Segurança da Informação'] : ['Public Information Security Policy']}
                corGradient='#2B2B2B00'
                colorida={false}
                logo={<Logo />}
            />
            <Secao
                bgColor={style.colors.white}
                color={style.colors.primary}
                titulo={language === 'pt-br' ? 'Objetivo' : 'Objective'}
                icone={<Objetivo />}
                qtdIcone={1}
                conteudo={
                    <ConteudoText color={style.colors.primary}>
                        {
                            language === 'pt-br' ?
                                "Para que a Segurança da Informação seja completamente eficaz, a CAFS Tecnologia implementou uma série de controles compostos por políticas, práticas, procedimentos, estruturas organizacionais e tecnologia." :
                                "To ensure Information Security is fully effective, CAFS Tecnologia has implemented a series of controls that include policies, practices, procedures, organizational structures, and technology."
                        }
                        <br></br>
                        <br></br>
                        {
                            language === 'pt-br' ?
                                "Este documento lista os principais controles utilizados pela CAFS Tecnologia para atender às necessidades da segurança da informação e cibernética, mitigando as vulnerabilidades a incidentes." :
                                "This document lists the primary controls used by CAFS Tecnologia to meet the needs of information and cybersecurity, mitigating vulnerabilities to incidents."
                        }
                    </ConteudoText>
                }
            />
            <Secao
                bgColor={style.colors.primary}
                color={style.colors.white}
                titulo={language === 'pt-br' ? 'Campo de aplicação' : 'Scope of Application'}
                icone={<Dados />}
                qtdIcone={1}
                conteudo={
                    <ConteudoText color={style.colors.white}>
                        {
                            language === 'pt-br' ?
                                "É aplicável a todos os colaboradores, terceiros, prestadores de serviço e parceiros da CAFS Tecnologia." :
                                "It applies to all CAFS Tecnologia employees, third parties, service providers, and partners."
                        }
                        <br></br>
                        <br></br>
                        {
                            language === 'pt-br' ?
                                "Termos e Definições" :
                                "Terms and Definitions"
                        }
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Alta Direção' : 'Top Management'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Pessoa ou grupo de pessoas que dirige e controla a CAFS Tecnologia em seu nível mais alto' : 'Person or group of people who directs and controls CAFS Tecnologia at the highest level'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Backup' : 'Backup'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Cópia de segurança de dados, de um dispositivo de armazenamento para outro, para que possa ser restaurado em caso da perda dos dados originais' : 'Data backup from one storage device to another, so it can be restored if original data is lost'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Código Malicioso' : 'Malicious Code'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'É um código de computador nocivo que tem como objetivo criar vulnerabilidades nos ativos da empresa' : 'Harmful computer code designed to create vulnerabilities in company assets'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Conformidade' : 'Compliance'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Cumprimento de um requisito' : 'Fulfillment of a requirement'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Criptografia' : 'Encryption'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'É a prática de codificar e decodificar dados' : 'The practice of encoding and decoding data'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Crise' : 'Crisis'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Qualquer evento que ameaça à integridade das pessoas, que gere alto impacto para o negócio e/ou reputação da empresa' : 'Any event that threatens people’s integrity, business operations, and/or the company’s reputation'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Evento' : 'Event'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Ocorrência ou mudança em um conjunto específico de circunstâncias que pode ter várias causas' : 'An occurrence or change in a set of specific circumstances that may have various causes'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Incidente' : 'Incident'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Situação que pode representar ou levar à interrupção de negócios, perdas, emergências ou crises' : 'A situation that may pose or lead to business interruption, losses, emergencies, or crises'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Infraestrutura' : 'Infrastructure'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Sistema de instalações, equipamentos e serviços necessários para a operação de uma organização' : 'System of facilities, equipment, and services necessary for an organization’s operation'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Firewall' : 'Firewall'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Equipamento e/ou software utilizado para controlar as conexões (que entram ou saem) de uma rede...' : 'Device and/or software used to control incoming and outgoing network connections...'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Log' : 'Log'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Expressão utilizada para descrever o processo de registro de eventos em um sistema computacional' : 'Term used to describe the process of recording events in a computer system'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Melhoria Contínua' : 'Continuous Improvement'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Atividade recorrente para melhorar o desempenho' : 'Recurring activity to improve performance'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'NBR ISO 27001' : 'NBR ISO 27001'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Organização brasileira que estabelece requisitos...' : 'Brazilian organization that establishes requirements...'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'NIST' : 'NIST'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Agência governamental não regulatória...' : 'Non-regulatory government agency...'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Partes interessadas (stakeholder)' : 'Stakeholders'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Pessoa ou organização que pode afetar...' : 'Person or organization that may affect...'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Pen-drive' : 'USB Drive'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Dispositivo de armazenamento de dados' : 'Data storage device'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Risco' : 'Risk'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Combinação da probabilidade de ocorrer...' : 'Combination of the likelihood of an event and its consequences...'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Rede Wireless' : 'Wireless Network'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Conhecida também como rede sem fio...' : 'Also known as wireless network...'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Teste de Intrusão' : 'Penetration Testing'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'É um método que avalia a segurança...' : 'A method that assesses the security...'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Vulnerabilidade' : 'Vulnerability'}
                                color={style.colors.white}
                                conteudo={language === 'pt-br' ? 'Fragilidade de um ativo ou grupo de ativos...' : 'Weakness of an asset or group of assets...'}
                            />
                        </ContainerCard>
                    </ConteudoText>
                }
            />

            <Secao
                bgColor={style.colors.darkGray}
                color={style.colors.white}
                titulo={language === 'pt-br' ? 'Introdução' : 'Introduction'}
                icone={<Introducao />}
                qtdIcone={1}
                conteudo={
                    <ConteudoText color={style.colors.white}>
                        {
                            language === 'pt-br' ?
                                "A CAFS Tecnologia, através de seu departamento de Segurança da Informação e de forma alinhada com os objetivos e requisitos do negócio, estabelece regras e direcionamentos baseados nas normas NBR ISO 27001, 27002 e no NIST National Institute of Standards and Technology a serem seguidos e aplicados a pessoas, processos e tecnologia, de forma a proteger as informações da CAFS Tecnologia e suas marcas, de seus clientes, fornecedores e parceiros de negócios" :
                                "CAFS Tecnologia, through its Information Security department and in alignment with business objectives and requirements, establishes rules and guidelines based on NBR ISO 27001, 27002 standards, and the NIST National Institute of Standards and Technology to be followed and applied to people, processes, and technology, aiming to protect the information of CAFS Tecnologia and its brands, clients, suppliers, and business partners."
                        }
                    </ConteudoText>
                }
            />

            <Secao
                bgColor={style.colors.white}
                color={style.colors.darkGray}
                titulo={language === 'pt-br' ? 'Diretrizes' : 'Guidelines'}
                icone={<IconCafs />}
                qtdIcone={1}
                conteudo={
                    <ConteudoText color={style.colors.darkGray}>
                        <h2 style={{ textAlign: 'start', fontSize: '20px', marginBottom: '1rem' }}>
                            {
                                language === 'pt-br' ? "Diretrizes Gerais" : "General Guidelines"
                            }
                        </h2>
                        {
                            language === 'pt-br' ?
                                "Com propósito de auxiliar seus funcionários e partes interessadas a entenderem as suas responsabilidades, para garantir que estejam em conformidade com as diretrizes previamente definidas e com a legislação, foram desenvolvidos alguns documentos para disseminar o conhecimento dos processos e procedimentos de segurança da informação." :
                                "In order to assist its employees and stakeholders in understanding their responsibilities, to ensure compliance with previously defined guidelines and legislation, several documents have been developed to disseminate knowledge of information security processes and procedures."
                        }
                        <br></br>
                        <br></br>
                        {
                            language === 'pt-br' ?
                                'Estes documentos estão em ambiente protegido contra alteração, estão disponíveis em local acessível aos colaboradores e são encaminhados às partes interessadas quando solicitado.' :
                                'These documents are in a protected environment against alteration, are available in a location accessible to employees, and are forwarded to stakeholders upon request.'
                        }
                        <h2 style={{ textAlign: 'start', fontSize: '20px', marginBottom: '1rem', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Segurança da Informação no Negócio" : "Information Security in Business"
                            }
                        </h2>
                        {
                            language === 'pt-br' ?
                                'A seguir estão relacionados os principais controles utilizados pela CAFS Tecnologia para proteger as informações, atender às necessidades da segurança cibernética e reduzir a vulnerabilidade a incidentes.' :
                                'The following are the main controls used by CAFS Tecnologia to protect information, meet cybersecurity needs, and reduce vulnerability to incidents.'
                        }
                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Acesso Lógico e Físico" : "Logical and Physical Access"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Acesso Físico à Salas Restritas' : 'Physical Access to Restricted Rooms'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Determina o perímetro e controle de acesso físico necessário para os ambientes que hospedam dispositivos críticos, amostras biológicas e documentos sigilosos' : 'Determines the perimeter and physical access control necessary for environments that host critical devices, biological samples, and confidential documents'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Acesso Lógico da Rede de Dados e Sistemas de Informação' : 'Logical Access to Data Networks and Information Systems'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes e requisitos dos controles de acesso de aplicações e sistemas do ambiente de tecnologia' : 'Establishes the guidelines and requirements for access controls of applications and systems in the technology environment'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Acesso a Rede Wireless Corporativa' : 'Access to Corporate Wireless Network'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes gerais de instalação, configuração, liberação de acesso e segurança para rede wireless' : 'Establishes general guidelines for installation, configuration, access authorization, and security for the wireless network'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gerenciar Senhas em Sistemas de Informação' : 'Manage Passwords in Information Systems'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes de utilização de senhas para auxiliar na segurança dos acessos à rede e aos sistemas informatizados' : 'Establishes guidelines for password use to aid in the security of access to the network and computerized systems'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Credenciais de acesso aos Sistemas Informáticos' : 'Access Credentials for Information Systems'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece os padrões para os tipos de credenciais, privilégios e nomenclatura das contas dos usuários para acesso aos sistemas informatizados' : 'Establishes standards for the types of credentials, privileges, and naming conventions of user accounts for access to computerized systems'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Conceder acesso à internet' : 'Grant Internet Access'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes de acesso à internet pelo tipo de perfil do usuário' : 'Establishes guidelines for internet access based on the user profile type'}
                            />
                        </ContainerCard>

                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Proteção de dados" : "Data protection"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Classificação, Rotulação e Tratamento da Informação' : 'Classification, Labeling, and Handling of Information'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes para a classificação, rotulação e tratamento das informações de acordo com sua sensibilidade e criticidade para a organização visando o estabelecimento de níveis adequados de proteção' : 'Establishes guidelines for the classification, labeling, and handling of information based on its sensitivity and criticality to the organization, aiming to establish adequate levels of protection'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Compartilhamento e armazenamento de informações' : 'Sharing and Storing Information'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece diretrizes para manter a segurança na troca ou compartilhamento de informações e conhecimento na empresa, e com quaisquer entidades externas. Bem como a criptografia de dados em repouso e em trânsito' : 'Establishes guidelines to maintain security in the exchange or sharing of information and knowledge within the company and with any external entities, as well as data encryption at rest and in transit'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Enviar, acessar e receber e-mails criptografados' : 'Send, Access, and Receive Encrypted Emails'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes para o envio e recebimento de e-mails criptografados' : 'Establishes guidelines for sending and receiving encrypted emails'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Tratar, Gerenciar e Descartar Mídias com Segurança' : 'Handle, Manage, and Dispose of Media Securely'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece diretriz para tratar, gerenciar e descartar com segurança mídias impressas ou digitais' : 'Establishes guidelines for handling, managing, and securely disposing of printed or digital media'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Acesso para Pen-drive' : 'Access for USB Drives'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes de acesso de pen-drive nos equipamentos da empresa pelo tipo de perfil do usuário' : 'Establishes access guidelines for USB drives on company equipment based on user profile type'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Prevenção contra vazamento de dados – DLP' : 'Data Leakage Prevention – DLP'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes para que os dados da empresa sejam gerenciados de maneira uniforme em todo o conglomerado, garantindo que estejam classificados como confidenciais e de uso restrito, não sejam compartilhados de maneira equívoca, tendenciosa e/ou maliciosa para usuários não autorizados' : 'Establishes guidelines for managing company data uniformly across the conglomerate, ensuring that data is classified as confidential and for restricted use, and is not shared inappropriately, tendentiously, and/or maliciously with unauthorized users'}
                            />
                        </ContainerCard>

                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Segurança na rede" : "Network Security"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Uso e Acesso à Internet' : 'Use and Access to the Internet'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece critérios e padrões para acesso e utilização da internet pelos usuários de rede mantendo a conformidade com a política de segurança da informação e com a legislação vigente' : 'Establishes criteria and standards for access and use of the internet by network users while maintaining compliance with the information security policy and current legislation'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Serviço de Mensageria Eletrônica' : 'Email Service'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece diretrizes para acesso e utilização do serviço de e-mails pelos usuários' : 'Establishes guidelines for access and use of the email service by users'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Aplicar Segurança Perimetral' : 'Apply Perimeter Security'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece diretrizes para aplicação de políticas de segurança em equipamentos, a fim de proteger e mitigar possíveis vulnerabilidades que podem ser criadas e que permitam um ataque externo (originado pela internet) ou lateral' : 'Establishes guidelines for applying security policies on equipment to protect and mitigate potential vulnerabilities that may be created and allow for external (internet-originated) or lateral attacks'}
                            />
                        </ContainerCard>

                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Segurança de aplicação" : "Application Security"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Aquisição, Manutenção e Desenvolvimento de Sistemas de Informação' : 'Acquisition, Maintenance, and Development of Information Systems'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece os requisitos mínimos para a aquisição, desenvolvimento e manutenção segura de aplicações e aplicativos visando a redução dos riscos associados de segurança dos dados utilizados pelas aplicações ou com a infraestrutura utilizada' : 'Establishes minimum requirements for the safe acquisition, development, and maintenance of applications and software aiming to reduce the associated risks of data security used by the applications or the infrastructure used'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Desenvolver Código Seguro em Softwares e Sistemas' : 'Develop Secure Code in Software and Systems'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece critérios e práticas para desenvolvimento seguro de aplicações, com objetivo de reduzir os riscos e impactos nas áreas de negócios, além de propiciar segurança e confiabilidade ao processo de desenvolvimento / implantação de mudanças e/ou novas versões de sistemas no ambiente de produção' : 'Establishes criteria and practices for the secure development of applications, aiming to reduce risks and impacts on business areas, as well as to provide security and reliability to the development/implementation process of changes and/or new versions of systems in the production environment'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Fundamentos e Orientações para Aquisição de Sistemas' : 'Fundamentals and Guidelines for System Acquisition'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Orienta e estabelece estratégia e métodos para aquisição de sistemas com segurança' : 'Guides and establishes strategy and methods for secure system acquisition'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Avaliar, testar e validar a segurança de aplicações desenvolvidas' : 'Evaluate, Test, and Validate the Security of Developed Applications'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece critério para avaliação, teste e validações de segurança de aplicações desenvolvidas em ambiente de homologação da CAFS Tecnologia' : 'Establishes criteria for the evaluation, testing, and validation of the security of applications developed in the CAFS Tecnologia homologation environment'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Criptografia para Sistemas de Informação' : 'Cryptography for Information Systems'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece um procedimento seguro definindo o escopo da aplicação de criptografia com o propósito de garantir um canal seguro para a comunicação das informações' : 'Establishes a secure procedure defining the scope of cryptography application with the purpose of ensuring a secure channel for information communication'}
                            />
                        </ContainerCard>

                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Segurança de Endpoint" : "Endpoint Security"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Prevenção contra Códigos Maliciosos' : 'Malware Prevention'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes de controle contra Códigos Maliciosos a serem aplicados nos dispositivos gerenciados pela CAFS Tecnologia' : 'Establishes guidelines for controlling Malware to be applied on devices managed by CAFS Tecnologia'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Uso Aceitável de Recursos de Tecnologia da Informação' : 'Acceptable Use of Information Technology Resources'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Orienta os colaboradores nas práticas para a correta utilização dos ativos e recursos de Tecnologia da Informação' : 'Guides employees on practices for the proper use of Information Technology assets and resources'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Backup' : 'Backup'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as diretrizes para a realização de backup de informações. Arquivos corporativos, sistemas de produção, programas, aplicativos, sistemas operacionais, bancos de dados, scripts, parâmetros de configuração e testes periódicos de restauração de arquivos armazenados nos servidores' : 'Establishes guidelines for performing backups of information, including corporate files, production systems, programs, applications, operating systems, databases, scripts, configuration parameters, and periodic testing of restoration of files stored on servers'}
                            />
                        </ContainerCard>

                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Gestão da cadeia de suprimentos" : "Supply Chain Management"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Avaliação de fornecedor' : 'Supplier Evaluation'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Determina os princípios e as regras para seleção, avaliação e reavaliação de provedores externos' : 'Establishes the principles and rules for selecting, evaluating, and re-evaluating external providers'}
                            />
                        </ContainerCard>
                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Prevenção" : "Prevention"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Mudanças' : 'Change Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as regras gerais do processo de Gerenciamento de Mudanças de TI, que visa assegurar a aplicação de métodos e controles que resultem no mínimo de impactos negativos, oriundos de mudanças mal planejadas e/ou executadas nos ativos' : 'Establishes the general rules of the IT Change Management process, aiming to ensure the application of methods and controls that result in minimal negative impacts from poorly planned and/or executed changes to assets'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Vulnerabilidades Técnicas' : 'Technical Vulnerability Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece os princípios e as regras para gestão de vulnerabilidades técnicas no ambiente identificando e mapeando riscos de ataques e exposição de ativos' : 'Establishes the principles and rules for managing technical vulnerabilities in the environment by identifying and mapping risks of attacks and asset exposure'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Auditorias de Tecnologia da Informação' : 'IT Audit Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece as regras para a realização de auditorias de segurança da informação visando minimizar os riscos de comprometimento dos sistemas ou das informações. Estas auditorias têm como objetivo avaliar usuários, sistema, seus mecanismos e controles' : 'Establishes the rules for conducting information security audits to minimize the risks of compromising systems or information. These audits aim to assess users, systems, their mechanisms, and controls'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Continuidade de Negócios' : 'Business Continuity Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece diretrizes a fim de minimizar os impactos negativos causados por quaisquer eventos que possam oferecer risco na continuidade dos negócios da CAFS Tecnologia. Responsável também por definir os papéis e responsabilidades necessárias para a execução do processo de gestão' : 'Establishes guidelines to minimize negative impacts caused by any events that may pose risks to the continuity of CAFS Tecnologia’s business. Also responsible for defining the roles and responsibilities necessary for executing the management process'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Teste de Intrusão' : 'Penetration Testing'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece diretrizes para execução do teste de Intrusão para buscar e identificar vulnerabilidades de segurança na rede, sistema ou ferramenta' : 'Establishes guidelines for conducting penetration testing to seek and identify security vulnerabilities in networks, systems, or tools'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Conscientização' : 'Awareness'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece programa de conscientização e treinamento de segurança para colaboradores e prestadores de serviço' : 'Establishes a security awareness and training program for employees and service providers'}
                            />
                        </ContainerCard>

                        <h3 style={{ borderTop: `1px solid ${style.colors.darkGray}`, paddingTop: '25px', textAlign: 'center', fontSize: '18px', fontFamily: style.typography.fontFamily.light, fontWeight: 'normal', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Centro de Defesa e Operações Cibernéticas" : "Cyber Defense and Operations Center"
                            }
                        </h3>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Incidentes de Segurança da Informação' : 'Information Security Incident Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece o processo para identificar, notificar e gerenciar os incidentes de segurança da informação e determina as regras para apurar, responsabilizar e aplicar as medidas corretivas e disciplinares em decorrência de violações da política de segurança da informação' : 'Establishes the process to identify, notify, and manage information security incidents and sets the rules for investigating, assigning responsibility, and applying corrective and disciplinary measures due to violations of the information security policy'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Crises em Tecnologia da Informação' : 'IT Crisis Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Estabelece o plano de gerenciamento de crises, que engloba procedimentos e instruções a serem adotados em situação de crise ou ameaça de crise' : 'Establishes the crisis management plan, which includes procedures and instructions to be followed in the event of a crisis or crisis threat'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Riscos' : 'Risk Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Este processo determina as diretrizes para identificar, analisar, medir e tratar os riscos de segurança da informação identificados no ambiente' : 'This process determines the guidelines for identifying, analyzing, measuring, and treating information security risks identified in the environment'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de Consequências em Segurança da Informação' : 'Management of Consequences in Information Security'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Este processo determina os critérios e procedimentos de Gestão de Consequências, resultante de incidentes de segurança da informação ocorridos no ambiente, a fim de padronizar as ações administrativas e assegurar a confidencialidade, disponibilidade e integridade das informações, sistemas, aplicações e ativos relacionados' : 'This process establishes the criteria and procedures for Consequence Management resulting from information security incidents occurring in the environment, aiming to standardize administrative actions and ensure the confidentiality, availability, and integrity of information, systems, applications, and related assets'}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Gestão de logs e eventos' : 'Log and Event Management'}
                                color={style.colors.darkGray}
                                conteudo={language === 'pt-br' ? 'Este processo gerencia as atividades ou eventos, através da análise de logs, detectando atividades não autorizadas ou inadequadas, de processamento da informação e atende requisitos legais relevantes, aplicáveis às suas atividades de registro e monitoramento' : 'This process manages activities or events through log analysis, detecting unauthorized or inappropriate activities in information processing and meets relevant legal requirements applicable to its recording and monitoring activities'}
                            />
                        </ContainerCard>



                    </ConteudoText>
                }
            />
            <Secao
                bgColor={style.colors.secondary}
                color={style.colors.primary}
                titulo={language === 'pt-br' ? 'Penalidades' : 'Penalties'}
                icone={<Penalidade />}
                qtdIcone={1}
                conteudo={
                    <ConteudoText color={style.colors.primary}>
                        {
                            language === 'pt-br' ?
                                "O não cumprimento desta Política de Segurança da Informação pode gerar riscos de segurança da informação, financeiros, vazamento de informações, uso impróprio e negativo da imagem da empresa, bem como não garantir a confidencialidade, integridade e disponibilidade da informação." :
                                "Non-compliance with this Information Security Policy may lead to information security risks, financial risks, information leaks, misuse and negative impact on the company's image, as well as failure to ensure the confidentiality, integrity, and availability of information."
                        }
                    </ConteudoText>
                }
            />

            <Secao
                bgColor={style.colors.primary}
                color={style.colors.white}
                titulo={language === 'pt-br' ? 'Responsabilidades' : 'Responsibilities'}
                icone={<IconCafs />}
                qtdIcone={1}
                conteudo={
                    <ConteudoText color={style.colors.white}>
                        <h2 style={{ textAlign: 'start', fontSize: '20px', marginBottom: '1rem' }}>
                            {
                                language === 'pt-br' ? "Colaboradores e Terceiros" : "Employees and Third Parties"
                            }
                        </h2>
                        {
                            language === 'pt-br' ?
                                "Todos os colaboradores e prestadores de serviço da CAFS Tecnologia são responsáveis pelo cumprimento dos princípios de Segurança da Informação descritos nas políticas, processos e procedimentos da organização." :
                                "All employees and service providers of CAFS Tecnologia are responsible for adhering to the principles of Information Security outlined in the organization's policies, processes, and procedures."
                        }
                        <h2 style={{ textAlign: 'start', fontSize: '20px', marginBottom: '1rem', marginTop: '70px' }}>
                            {
                                language === 'pt-br' ? "Área de Segurança da Informação" : "Information Security Area"
                            }
                        </h2>
                        <ContainerCard>
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Definir, manter e conscientizar sobre as políticas corporativas de segurança da informação, seus procedimentos e padrões' : 'Define, maintains, and raises awareness about corporate information security policies, their procedures, and standards'}
                                color={style.colors.white}
                                conteudo={''}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Realizar o planejamento e condução de capacitações periódicas com o objetivo de disseminar a cultura de segurança dentro da empresa, bem como de comunicar as atualizações eventualmente efetuadas nas políticas corporativas de segurança da informação, seus procedimentos e padrões' : 'Plan and conduct periodic training to promote a culture of security within the company, as well as communicate any updates made to the corporate information security policies, procedures, and standards.'}
                                color={style.colors.white}
                                conteudo={''}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Garantir a concessão de acesso, término ou mudança de direitos de acesso à rede e aplicações críticas de acordo com o perfil do usuário' : 'Ensure the granting, termination, or modification of access rights to the network and critical applications according to the user’s profile.'}
                                color={style.colors.white}
                                conteudo={''}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Garantir a melhoria de segurança da informação e segurança cibernética da organização através de projetos e iniciativas' : 'Ensure the improvement of information security and cybersecurity within the organization through projects and initiatives.'}
                                color={style.colors.white}
                                conteudo={''}
                            />
                            <Card
                                tituloPrimeiro={false}
                                titulo={language === 'pt-br' ? 'Conduzir a Gestão de Incidentes de Segurança da Informação, incluindo o apoio nas investigações para determinar as causas e os responsáveis, e realizar a comunicação interna dos fatos ocorridos' : 'Conduct Information Security Incident Management, including supporting investigations to determine causes and responsibilities, and carrying out internal communication regarding the incidents that occurred.'}
                                color={style.colors.white}
                                conteudo={''}
                            />
                        </ContainerCard>
                    </ConteudoText>
                }
            />
            <Secao
                bgColor={style.colors.white}
                color={style.colors.primary}
                titulo={language === 'pt-br' ? 'Canal de Comunicação de Segurança da Informação' : 'Information Security Communication Channel'}
                icone={<Canal />}
                qtdIcone={1}
                conteudo={
                    <ConteudoText color={style.colors.primary}>
                        {
                            language === 'pt-br' ?
                                "Para comunicar algum incidente, encaminhe para: incidente.si@cafstecnologia.com.br" :
                                "To report an incident, please send it to: incidente.si@cafstecnologia.com.br"
                        }
                    </ConteudoText>
                }
            />
            <Footer />
        </>
    );
}

export default SegurancaInfoPage;

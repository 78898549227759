import React, { useState } from "react";
import styled from "styled-components";
import { style } from "../../styles";

import { ReactComponent as Menu } from '../../assets/images/icon/menu.svg';
import { ReactComponent as Close } from '../../assets/images/icon/x.svg';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import useLanguage from '../../state/hooks/setLingua';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 70px;
    padding: 0 20%;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Icone = styled.div`
    margin-top: .8rem;
    width: 70px;
    height: 70px;
`

const Content = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: ${style.screens.small}) {
        display: none;
    }
`

const Text = styled.div`
    font-family: ${style.typography.fontFamily.regular};
    font-weight: 100;
    font-size:  15px;
    text-decoration: none;
    color: ${style.colors.white};
    position: relative;
    
    &::after {
        content: ''; 
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: ${style.colors.white};
        transition: width 0.5s;
    }

    &:hover::after {
        width: 100%;
    }
`;

const ContainerMenu = styled.div`
    width: 100%;
    height: 100vh;
    z-index: 4;
    background-color: ${style.colors.secondary};
    position: fixed;
    top: 0px;
    left: 0;
    box-sizing: border-box;
    animation: open-menu 0.8s ease-out both;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30vh 0;

    @keyframes open-menu {
        0% {
            transform: translateY(-1000px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @media screen and (min-width: ${style.screens.small}) {
        display: none;
    }
`

const ItemMenu = styled.div`
    font-family: ${style.typography.fontFamily.regular};
    font-size:  15px;
    text-decoration: none;
    color: ${style.colors.primary};
    position: relative;

    &::after {
        content: ''; 
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: ${style.colors.primary};
        transition: width 0.5s;
    }

    &:hover::after {
        width: 100%;
    }
`

const MenuButton = styled.div`
    width: 35px;
    height: 35px;
    position: fixed;
    top: 55px;
    right: 10px;
    z-index: 10000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    background-color: ${style.colors.white};
    border-radius: 1.5rem;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.7);
    transition: width 0.5s ease;
    color: ${style.colors.darkGray};
    text-decoration: none;

    & svg {
        width: auto;
        height: 28px;
    }

    @media screen and (min-width: ${style.screens.small}) {
        display: none;
    }
`

const links = [
    { label: "Home", link: "/" },
    { label: "Produtos", link: "/#produtos" },
    { label: "Serviços", link: "/#servicos" },
    { label: "Sobre", link: "/sobre" },
    { label: "Parceiros", link: "/#parceiros" },
    { label: "Contato", link: "/contato" },
]

const linksIngles = [
    { label: "Home", link: "/" },
    { label: "Products", link: "/#produtos" },
    { label: "Services", link: "/#servicos" },
    { label: "About", link: "/sobre" },
    { label: "Partners", link: "/#parceiros" },
    { label: "Contact", link: "/contato" },
]

const Navegacao = ({ logo }) => {
    const { language } = useLanguage();
    const [menu, setMenu] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    };

    return(
        <Container>
            <Link to='/'>
                <Icone>
                    {logo}
                </Icone>
            </Link>
            <MenuButton menu={menu} onClick={() => setMenu(!menu)}>
                {
                    menu ?
                    <Close /> : <Menu />
                }
            </MenuButton>
            {
                menu ?
                <ContainerMenu>
                    {
                        language === 'pt-br' ?
                        links.map(({ label, link }, index) => (
                            /#/.test(link) ?
                            <HashLink smooth style={{ textDecoration: 'none' }} to={link}>
                                <ItemMenu key={index}>{label}</ItemMenu>
                            </HashLink> :
                            <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to={link}>
                                <ItemMenu key={index}>{label}</ItemMenu>
                            </Link>

                        )) :
                        linksIngles.map(({ label, link }, index) => (
                            /#/.test(link) ?
                            <HashLink smooth style={{ textDecoration: 'none' }} to={link}>
                                <ItemMenu key={index}>{label}</ItemMenu>
                            </HashLink> :
                            <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to={link}>
                                <ItemMenu key={index}>{label}</ItemMenu>
                            </Link>

                        ))
                    }
                </ContainerMenu> : null
            }
            <Content>
                {
                    language === 'pt-br' ?
                    links.map(({ label, link }, index) => (
                        /#/.test(link) ?
                        <HashLink smooth style={{ textDecoration: 'none' }} to={link}>
                            <Text key={index}>{label}</Text>
                        </HashLink> :
                        <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to={link}>
                            <Text key={index}>{label}</Text>
                        </Link>

                    )) :
                    linksIngles.map(({ label, link }, index) => (
                        /#/.test(link) ?
                        <HashLink smooth style={{ textDecoration: 'none' }} to={link}>
                            <Text key={index}>{label}</Text>
                        </HashLink> :
                        <Link style={{ textDecoration: 'none' }} onClick={scrollToTop} to={link}>
                            <Text key={index}>{label}</Text>
                        </Link>

                    ))
                }
            </Content>
        </Container>
    )
}

export default Navegacao;
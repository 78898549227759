import styled from "styled-components";
import { style } from "../../styles";
import { useEffect, useState, useRef } from "react";
import { ReactComponent as Arrow } from '../../assets/images/icon/arrow.svg';
import axios from "axios";
import DownloadJsonButton from "../Download";
import useLanguage from "../../state/hooks/setLingua";

const ContainerFiltro = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: start;
  flex-wrap: wrap;
  border-bottom: 1px solid ${style.colors.darkGray};
  padding-bottom: 1rem;
  color: ${style.colors.darkGray};

  & p {
    width: 100%;
    text-align: start;
    font-family: ${style.typography.fontFamily.light};
    font-size: 14px;
  }
`;

const Input = styled.input`
  font-family: ${style.typography.fontFamily.light};
  padding: .3rem .5rem;
  color: ${style.colors.darkGray};
  border: 1px solid ${style.colors.darkGray};
  border-radius: 3px;
  font-size: 14px;
  width: 200px;
  margin-right: 1rem;
`;

const Select = styled.div`
  font-family: ${style.typography.fontFamily.regular};
  width: 200px;
  cursor: pointer;
  color: ${style.colors.darkGray};
  font-size: 14px;
  position: relative;
  margin-right: 1rem;

  & label {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${style.colors.darkGray};
    padding: .3rem .5rem;
    border-radius: 3px;
    box-sizing: border-box;
  }

  & section {
    position: absolute;
    top: 60px;
    background-color: white;
    width: 100%;
    max-height: 200px;
    box-sizing: border-box;
    padding: .5rem .8rem;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    z-index: 10000;
    overflow: auto;
  }

  & span {
    display: flex;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  & section div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 1rem .5rem;
    box-sizing: border-box;
    width: 100%;
    height: 25px;
    border-radius: 6px;

    &:hover {
      background-color: rgba(0,0,0,0.15);
    }
  }

  & section p {
    width: auto;
    margin-left: .5rem;
  }
`;

const TabelaContainer = styled.div`
  max-height: 650px;
  overflow: auto;
  margin-top: 1rem;
`;

const Tabela = styled.table`
  border-radius: 5px;
  border: none;
  border-collapse: collapse;
  width: 100%;
  background-color: white;

  & tbody {
    font-family: ${style.typography.fontFamily.light};
    font-size: 16px;
  }

  & thead {
    font-family: ${style.typography.fontFamily.semiBold};
    font-size: 14px;
  }

  & td, & th {
    text-align: center;
    padding: 8px;
  }

  & td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  & thead th {
    color: #ffffff;
    background: ${style.colors.nexus};
  }

  & thead th:nth-child(odd) {
    color: #ffffff;
    background: ${style.colors.nexus};
  }

  & tr:nth-child(even) {
    background: #F8F8F8;
  }
`;

const InfoRegistradoras = () => {
  const { language } = useLanguage()

  const [openSelectRegist, setOpenSelectRegist] = useState(false);

  const startSelect = language === 'pt-br' ? "Todas" : 'All'
  
  const [selectRegist, setSelectRegist] = useState(startSelect);
  const [searchTerm, setSearchTerm] = useState("");

  const [dados, setDados] = useState([]);
  const [filteredDados, setFilteredDados] = useState(null);

  const refRegist = useRef();

  useEffect(() => {
    axios.get('https://site-corporativo.azurewebsites.net/api/participantes-registradoras?code=Qy54fcJ1pQVyjvQ7zwzzbqim-thq0nWBYzxWbHeQbuRLAzFutE3eyA%3D%3D').then(res => {
      setDados(res.data.result);
      setFilteredDados(res.data.result);
    }
    )

  }, []);

  useEffect(() => {
    filterDados();
  }, [selectRegist, searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refRegist.current && !refRegist.current.contains(event.target)) {
        setOpenSelectRegist(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filterDados = () => {
    let filtered = dados;
    if (selectRegist) {
      filtered = filtered.filter(item => item.registradora === selectRegist || selectRegist === 'Todas' || selectRegist === 'All');
    }
    if (searchTerm) {
      filtered = filtered.filter(item =>
        (item.cliente && item.cliente.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.cnpj && item.cnpj.includes(searchTerm))
      );
    }
    setFilteredDados(filtered);
  };

  const handleSelectRegist = (value) => {
    setSelectRegist(value);
    setOpenSelectRegist(false);
  };

  return (
    <>
      <h3 style={{ marginBottom: '2rem', fontSize: '16px', fontFamily: style.typography.fontFamily.semiBold }}>{language === 'pt-br' ? 'Clientes/Participantes das Registradoras' : 'Clients/Participants of Registrars'}</h3>
      <ContainerFiltro>
        <p>{language === 'pt-br' ? 'Filtros' : 'Filters'}</p>
        <Input
          type="text"
          placeholder={language === 'pt-br' ? "Digite um nome ou CNPJ" : 'Enter a name or CNPJ'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Select ref={refRegist}>
          <p>{language === 'pt-br' ? 'Registradora' : 'Registrars'}</p>
          <label onClick={() => setOpenSelectRegist(!openSelectRegist)}>
            <p>{selectRegist}</p>
            <Arrow />
          </label>
          {openSelectRegist && (
            <section>
              <div onClick={() => handleSelectRegist('B3')}>
                <span style={{ backgroundColor: '#FFD700' }}></span>
                <p>B3</p>
              </div>
              <div onClick={() => handleSelectRegist('CERC')}>
                <span style={{ backgroundColor: '#9ACD32' }}></span>
                <p>CERC</p>
              </div>
              <div onClick={() => handleSelectRegist('NUCLEA')}>
                <span style={{ backgroundColor: '#B0E0E6' }}></span>
                <p>NUCLEA</p>
              </div>
              <div onClick={() => handleSelectRegist('TAG')}>
                <span style={{ backgroundColor: '#BA55D3' }}></span>
                <p>TAG</p>
              </div>
              <div onClick={() => handleSelectRegist(language === 'pt-br' ? 'Todas' : 'All')}>
                <p>{language === 'pt-br' ? 'Todas' : 'All'}</p>
              </div>
            </section>
          )}
        </Select>
        <DownloadJsonButton data={filteredDados} />
      </ContainerFiltro>
      <TabelaContainer>
        <Tabela>
          <thead>
            <tr>
              <th>Razão Social</th>
              <th>CNPJ</th>
              <th>Tipo</th>
              <th>Registradora</th>
            </tr>
          </thead>
          <tbody>
            {
              selectRegist === 'B3' ? <p style={{ fontFamily: style.typography.fontFamily.semiBold }}>{language === 'pt-br' ? 'Em construção' : 'No participant found'}</p> :
                filteredDados ? (
                  filteredDados.map((item, index) => (
                    item.papel === 'INSTITUIÇÃO FINANCEIRA - - Atualizado: .. Nº de ClientesCNPJClientesPapel REGISTRADORA NÚCLEA - CLIENTES PRODUÇÃO' ? null :
                    <tr key={index}>
                      <td style={{ width: '50%', fontFamily: style.typography.fontFamily.medium, color: style.colors.darkGray  }}>{item.cliente}</td>
                      <td style={{ width: '20%', fontFamily: style.typography.fontFamily.medium, color: style.colors.darkGray }}>{item.cnpj}</td>
                      <td style={{ width: '20%', fontFamily: style.typography.fontFamily.medium, color: style.colors.darkGray }}>{item.papel}</td>
                      <td>
                        <p style={{ padding: '2px 0', borderRadius: '30px', fontFamily: style.typography.fontFamily.bold, fontSize: '14px', color: style.colors.darkGray, backgroundColor: item.registradora === 'B3' ? '#FFD700' : item.registradora === 'CERC' ? '#9ACD32' : item.registradora === 'TAG' ? '#BA55D3' : '#B0E0E6' }}>
                          {item.registradora}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : filteredDados && filteredDados.length === 0 ? <p style={{ fontFamily: style.typography.fontFamily.semiBold }}>{language === 'pt-br' ? 'Carregando...' : 'Loading...'}</p> : (
                  <p style={{ fontFamily: style.typography.fontFamily.semiBold }}>{language === 'pt-br' ? 'Nenhum participante encontrado' : 'No participant found'}</p>
                )
            }
          </tbody>
        </Tabela>
      </TabelaContainer>
    </>
  );
};

export default InfoRegistradoras;
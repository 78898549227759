import React from "react";
import { style } from "../../../styles";
import { ReactComponent as IconCafs } from '../../../assets/images/icon/cafs-colorido.svg';
import Secao from "../../../components/Secao";
import Card from "../../../components/Card";
import ConteudoText from "../../../components/ConteudoText";
import ContainerCard from "../../../components/ContainerCard";

import { ReactComponent as IconMissao } from '../../../assets/images/icon/missao.svg';
import { ReactComponent as IconVisao } from '../../../assets/images/icon/visao.svg';
import { ReactComponent as IconValores } from '../../../assets/images/icon/valores.svg';

import useLanguage from "../../../state/hooks/setLingua";

const SectionSobre = () => {
  const { language } = useLanguage();

  return (
    <Secao
      titulo={language === 'pt-br' ? 'Descubra a Essência da CaFs' : 'Discover the Essence of CaFs '}
      subtitulo={language === 'pt-br' ? 'Nossa Jornada de Inovação e Compromisso' : 'Our Journey of Innovation and Commitment'}
      bgColor={style.colors.secondary}
      color={style.colors.primary}
      conteudo={
        <>
          <ConteudoText color={style.colors.darkGray}>
            {
              language === 'pt-br' ?
                'Somos uma empresa familiar, fundada em 2009, com valores sólidos que nos diferenciam das demais. Nossa maior motivação é de sempre superar as expectativas. Nosso diferencial está na forma como trabalhamos, oferecendo suporte a empresas e pessoas, independentemente do setor, orçamento ou dificuldades.' :
                'We are a family-owned company founded in 2009 with solid values that set us apart. Our greatest motivation is to always exceed expectations. Our differential lies in the way we work, offering support to companies and individuals regardless of budget or difficulties.'
            }
            <br></br>
            <br></br>
            {
              language === 'pt-br' ?
              'Nosso principal objetivo é fazer a tecnologia trabalhar a favor dos nossos clientes, tornando-a uma aliada em seus negócios. Queremos ser seu parceiro confiável.' :
              'Our main objective is to make technology work for our clients, making it an ally in their business. We want to be your trusted partner. '
            }
          </ConteudoText>

          <ContainerCard>
            <Card
              color={style.colors.darkGray}
              conteudo={language === 'pt-br' ? 'Impulsionar o sucesso dos clientes com soluções tecnológicas inovadoras e de qualidade' : 'To drive client success with innovative and quality technological solutions'}
              icone={<IconMissao />}
              titulo={language === 'pt-br' ? 'Missão' : 'Mission'}
              tituloPrimeiro={true}
            />
            <Card
              color={style.colors.darkGray}
              conteudo={language === 'pt-br' ? 'Liderar o mercado de tecnologia com excelência e inovação, sendo a parceira estratégica de longo prazo para os clientes.' : 'To lead the technology market with excellence and innovation, being the strategic long-term partner for our clients.'}
              icone={<IconVisao />}
              titulo={language === 'pt-br' ? 'Visão' : 'Vision'}
              tituloPrimeiro={true}
            />
            <Card
              color={style.colors.darkGray}
              conteudo={language === 'pt-br' ? 'Valorizamos as pessoas, trabalhamos em equipe, respeitamos em todas as relações, e buscamos aprendizado contínuo.' : 'We value people, work as a team, respect all relationships, and seek continuous learning.'}
              icone={<IconValores />}
              titulo={language === 'pt-br' ? 'Valores' : 'Values'}
              tituloPrimeiro={true}
            />
          </ContainerCard>
        </>
      }
      icone={<IconCafs />}
      qtdIcone={1}
    />
  )
}

export default SectionSobre;
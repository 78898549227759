import React from "react";
import styled from "styled-components";
import Botao from '../../components/Botao';
import { style } from "../../styles";

const Container = styled.div`
    position: absolute;
    width: 50%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

const Box = styled.div`
    width: 60%;
    position: relative;
    height: 200px;
    margin: 0 auto 20px;
    z-index: -1;
`;

const Titulo = styled.h1`
    font-family: ${style.typography.fontFamily.light};
    font-size: 236px;
    font-weight: 100;
    margin: 0;
    color: #211b19;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

const Text = styled.h2`
    font-family: ${style.typography.fontFamily.regular};
    font-size: 24px;
    width: auto;
    color: #211b19;
    background: #fff;
    padding: 10px 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
`;

const PageNotFound = () => {
    return (
        <Container>
            <Box>
                <Titulo>Oops!</Titulo>
                <Text>404 - A PÁGINA NÃO FOI ENCONTRADA</Text>
            </Box>
            <Botao link={'/'} label={'Voltar para página inicial'} fullWidth={true} fontSize={'22px'} bgColor={style.colors.primary} />
        </Container>
    )
};

export default PageNotFound;
import React from "react";
import styled from "styled-components";
import { style } from "../../styles";

const ContainerInput = styled.div`
    width: 40%;
    height: 60px;

    @media screen and (max-width: ${style.screens.medium}) {
        margin-bottom: 3rem;
        width: 100%;
    }
`;

const Label = styled.label`
    width: 100%;
    font-size: ${style.typography.fontSize.p};
    font-family: ${style.typography.fontFamily.medium};
    color: ${props => props.error ? '#DC1A1A' : style.colors.darkGray};
    
    @media screen and (max-width: ${style.screens.medium}) {
        width: 39%;
    }
`;

const InpuArea = styled.textarea`
    width: 100%;
    font-size: ${style.typography.fontSize.p};
    font-family: ${style.typography.fontFamily.light};
    box-shadow: none;
    border: none;
    outline: none;
    border-bottom: 1px solid ${props => props.error ? '#DC1A1A' : '#8D8D8D'};

    &::focus {
        border: none;
        outline: none;
    }
`

const Span = styled.span`
    font-family: ${style.typography.fontFamily.light};
    font-size: 12px;
    color: #DC1A1A;

`


const InputAreaComponent = ({ error, label, placeholder, onChange, onBlur, value }) => {
    return (
        <ContainerInput style={{ width: '100%' }}>
            <Label error={error}>{label}</Label>
            <InpuArea
                placeholder={placeholder}
                error={error}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
            />
            {error ? <Span>Mensagem é obrigatória</Span> : null}
        </ContainerInput>
    )
}

export default InputAreaComponent;
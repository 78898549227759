import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import homeImage from '../../assets/images/photo/home.jpg'
import { ReactComponent as Logo } from '../../assets/images/photo/cafs-colorido.svg';
import SectionSobre from "./sections/sobre";
import SectionProdutos from "./sections/produtos";
import SectionServicos from "./sections/servicos";
import SectionClientes from "./sections/clientes";
import SectionParceiros from "./sections/parceiros";
import Footer from "../../components/Footer";
import useLanguage from "../../state/hooks/setLingua";

const Homepage = () => {
  const { language } = useLanguage();

  return (
    <>
      <Funcbar backgroundColor={style.colors.primary} />
      <Banner
        img={homeImage}
        titulo={language === 'pt-br' ? ['Venha para a CaFs'] : ['Come to CaFs']}
        subtitulo={language === 'pt-br' ? ['Onde a tecnologia', 'trabalha ao seu lado'] : ['Where technology', 'works by your side']}
        frase={language === 'pt-br' ? ['Será um prazer recebê-lo como cliente,', 'parceiro e, quem sabe, um amigo'] : ['It will be a pleasure to welcome you as a client,', 'partner, and perhaps, a friend.']}
        botao={language === 'pt-br' ? 'Fale Conosco' : 'Talk to us'}
        corGradient='#003F8060'
        colorida={true}
        logo={<Logo />}
      />
      <SectionSobre />
      <SectionProdutos />
      <SectionServicos />
      <SectionClientes />
      <SectionParceiros />
      <Footer />
    </>
  );
}

export default Homepage;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { style } from "../../styles";

const Container = styled.div`
    width: ${props => props.tamanho === 2 ? '38%' : '28%'};
    margin: 0px 2.6%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    background-color: transparent;
    color: ${props => props.color};
    margin-bottom: 2rem;
    animation: ${props => props.isVisible ? 'start 1s ease' : 'none'};
    @keyframes start {
        0% {
            transform: translateX(-1000px);
            opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }

    @media screen and (max-width: ${style.screens.medium}) {
        width: 100%;
    }
`

const Titulo = styled.div`
    width: 100%;
    font-size: 16px;
    font-family: ${style.typography.fontFamily.semiBold};
    font-weight: 100;
    text-align: ${props => props.tituloPrimeiro ? 'start' : 'center'};
    margin-bottom: .5rem;
`

const Icone = styled.div`
    width: 70px;
    height: 70px;
    margin-bottom: 1.2rem;
`

const Conteudo = styled.div`
    width: 100%;
    font-size: 16px;
    font-family: ${style.typography.fontFamily.light};
    font-weight: 100;
    text-align: center;
`

const Card = ({ titulo, conteudo, icone, tituloPrimeiro, color, tamanho }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const top = ref.current.getBoundingClientRect().top;
                const bottom = ref.current.getBoundingClientRect().bottom;
                const windowHeight = window.innerHeight;
                if (top < windowHeight && bottom > 0) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return(
        <Container ref={ref} isVisible={isVisible} color={color} tamanho={tamanho}>
            {
                tituloPrimeiro ?
                (   
                    <>
                        <Titulo tituloPrimeiro={tituloPrimeiro}>{titulo}</Titulo>
                        <Icone>{icone}</Icone>
                        <Conteudo>{conteudo}</Conteudo>
                    </>
                ) :
                (
                    <>
                        <Icone>{icone}</Icone>
                        <Titulo tituloPrimeiro={tituloPrimeiro}>{titulo}</Titulo>
                        <Conteudo>{conteudo}</Conteudo>
                    </>
                )
            }
        </Container>
    )
}

export default Card;

import Banner from "../../components/Banner";
import Funcbar from "../../components/FuncBar";
import { style } from "../../styles";
import sensebotImage from '../../assets/images/photo/sensebot.jpg'

import { ReactComponent as Logo } from '../../assets/images/icon/sensebotColorido.svg';

import { ReactComponent as IconSensebot } from '../../assets/images/icon/sensebot.svg';
import { ReactComponent as Foguete } from '../../assets/images/icon/foguete.svg';
import Secao from "../../components/Secao";
import ConteudoText from "../../components/ConteudoText";
import ContainerCard from "../../components/ContainerCard";
import Card from "../../components/Card";

import Botao from "../../components/Botao";

import { ReactComponent as Horas } from '../../assets/images/icon/24.svg';
import { ReactComponent as FastResponse } from '../../assets/images/icon/fast-response.svg';
import { ReactComponent as Automation } from '../../assets/images/icon/automacao.svg';
import { ReactComponent as Segment } from '../../assets/images/icon/segment.svg';
import { ReactComponent as Database } from '../../assets/images/icon/dados.svg';
import { ReactComponent as Integration } from '../../assets/images/icon/integracao.svg';
import Footer from "../../components/Footer";
import useLanguage from "../../state/hooks/setLingua";

const SensebotPage = () => {
  const { language } = useLanguage();

  return (
    <>
      <Funcbar backgroundColor={style.colors.sensebot} />
      <Banner
        img={sensebotImage}
        titulo={['Sensebot']}
        subtitulo={language === 'pt-br' ? ['O poder da informação', 'na ponta dos dedos'] : ['The power of information', 'at your fingertips']}
        corGradient='#2B2B2B00'
        colorida={false}
        logo={<Logo />}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Descrição do serviço' : 'Service Description'}
        bgColor={style.colors.secondary}
        color={style.colors.sensebot}
        conteudo={
          <>
            <ConteudoText color='#000'>
              {
                language === 'pt-br' ? 'As interações automatizadas e inteligentes se tornaram essenciais para empresas que desejam oferecer uma experiência aprimorada aos seus clientes. Com nosso Serviço de Chatbot e Generative AI, chamado SenseBot, oferecemos soluções avançadas e inovadoras de inteligência artificial para criar chatbots inteligentes e interativos que impulsionam o envolvimento do usuário e melhoram a eficiência operacional.' :
                  'Automated and intelligent interactions have become essential for companies that want to offer an enhanced customer experience. With our Chatbot and Generative AI Service called SenseBot, we offer advanced and innovative artificial intelligence solutions to create intelligent and interactive chatbots that drive user engagement and improve operational efficiency.'
              }
              <br></br>
              <br></br>
              {
                language === 'pt-br' ? 'Utilizando algoritmos de aprendizado de máquina e técnicas de processamento de linguagem natural, criamos chatbots inteligentes capazes de compreender e responder às necessidades dos seus clientes de forma rápida e precisa.' :
                  `Using machine learning algorithms and natural language processing techniques, we create smart chatbots capable of quickly and accurately understanding and responding to your customers' needs.`
              }
            </ConteudoText>
          </>
        }
        icone={<IconSensebot />}
        qtdIcone={1}
      />
      <Secao
        titulo={language === 'pt-br' ? 'Principais benefícios' : 'Main Benefits'}
        bgColor={style.colors.sensebot}
        color={style.colors.darkGray}
        conteudo={
          <>
            <ContainerCard>
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Atendimento 24/7' : '24/7 Support'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Com chatbots automatizados, seus clientes podem receber suporte e informações a qualquer hora do dia ou da noite, melhorando a experiência do usuário e aumentando a satisfação' : 'With automated chatbots, your customers can receive support and information at any time of day or night, improving user experience and satisfaction.'}
                icone={<Horas />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Respostas rápidas e precisas' : 'Fast and Accurate Responses'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Nossos chatbots são treinados para entender e responder às perguntas dos clientes de forma rápida e precisa, proporcionando respostas relevantes e úteis em tempo real' : 'Our chatbots are trained to understand and respond to customer questions quickly and accurately, providing relevant and useful answers in real-time.'}
                icone={<FastResponse />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Automação de tarefas' : 'Task Automation'}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Os chatbots podem automatizar tarefas repetitivas, como responder a perguntas frequentes, fornecer informações básicas ou direcionar os clientes para os recursos certos' : "Chatbots can automate repetitive tasks such as answering frequently asked questions, providing basic information, or directing customers to the right resources."}
                icone={<Automation />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Personalização e segmentação' : "Personalization and Segmentation"}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Com o uso de Generative AI, podemos criar chatbots altamente personalizados que se adaptam às preferências e necessidades individuais dos seus clientes, oferecendo uma experiência personalizada e relevante' : "Using Generative AI, we can create highly personalized chatbots that adapt to the individual preferences and needs of your customers, offering a personalized and relevant experience."}
                icone={<Segment />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Coleta de dados e análises' : "Data Collection and Analysis"}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Os chatbots podem coletar dados valiosos sobre as interações dos clientes, permitindo que você obtenha insights valiosos para melhorar seus produtos, serviços e processos' : "Chatbots can collect valuable data on customer interactions, allowing you to gain valuable insights to improve your products, services, and processes."}
                icone={<Database />}
              />
              <Card
                tituloPrimeiro={false}
                titulo={language === 'pt-br' ? 'Integração perfeita' : "Seamless Integration"}
                color={style.colors.darkGray}
                conteudo={language === 'pt-br' ? 'Nossos chatbots podem ser integrados facilmente aos seus sistemas existentes, como plataformas de atendimento ao cliente, CRM ou website, garantindo uma experiência contínua e integrada para os seus clientes' : "Our chatbots can be easily integrated with your existing systems, such as customer service platforms, CRM, or website, ensuring a seamless and integrated experience for your customers."}
                icone={<Integration />}
              />
            </ContainerCard>
            <ConteudoText color={style.colors.white}>
              {
                language === 'pt-br' ?
                  "Com nosso Serviço de Chatbot e Generative AI, você terá acesso às mais recentes inovações em inteligência artificial para impulsionar o atendimento ao cliente, aumentar a eficiência operacional e melhorar a experiência geral do usuário." :
                  "With our Chatbot and Generative AI Service, you will have access to the latest innovations in artificial intelligence to enhance customer service, increase operational efficiency, and improve the overall user experience."
              }
              <br></br>
              <br></br>
              {
                language === 'pt-br' ?
                  "Entre em contato conosco hoje mesmo e descubra como nosso Serviço de Chatbot e Generative AI pode ajudar a transformar a maneira como você se comunica com seus clientes, proporcionando uma interação inteligente e eficaz." :
                  "Contact us today and discover how our Chatbot and Generative AI Service can help transform the way you communicate with your customers, providing intelligent and effective interaction."
              }
            </ConteudoText>
            <Botao link='/contato' fullWidth={true} bgColor={style.colors.sensebot} fontSize={style.typography.fontSize.h4} label={language === 'pt-br' ? 'Fale conosco' : 'Talk to us'} />
          </>
        }
        icone={<Foguete />}
        qtdIcone={1}
      />
      <Footer />
    </>
  );
}

export default SensebotPage;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import Microsoft from '../../assets/images/photo/parceiros/microsoft-partner.png'
import Solarwinds from '../../assets/images/photo/parceiros/2560px-Solarwinds.svg.png'
import parceiro1 from '../../assets/images/photo/parceiros/629a35743e59ee069da94c64.png'
import Dell from '../../assets/images/photo/parceiros/Dell_Logo.svg.png'
import Equinix from '../../assets/images/photo/parceiros/Equinix_logo.png'
import Grafana from '../../assets/images/photo/parceiros/Grafana-logo-2.png'
import Intel from '../../assets/images/photo/parceiros/Intel_logo_(2006-2020).svg.png'
import Appdynamics from '../../assets/images/photo/parceiros/appdynamics-logo-A56FC33C67-seeklogo.com.png'
import atlassian from '../../assets/images/photo/parceiros/atlassian-logo-DF2FCF6E4D-seeklogo.com.png'
import parceiro2 from '../../assets/images/photo/parceiros/download.png'
import intelbras from '../../assets/images/photo/parceiros/intelbras-logo-6-2.png'
import kaspersky from '../../assets/images/photo/parceiros/kaspersky-logo.png'
import rundeck from '../../assets/images/photo/parceiros/rundeck.svg'
import zabbix from '../../assets/images/photo/parceiros/zabbix_logo_500x131.png'
import axis from '../../assets/images/photo/parceiros/axis.jpg'
import xlabs from '../../assets/images/photo/parceiros/xlabs.png'
import b3 from '../../assets/images/photo/parceiros/b3-logo.png'
import d42 from '../../assets/images/photo/parceiros/device-42.png'
import aws from '../../assets/images/photo/parceiros/aws.png'

import cafsLogo from '../../assets/images/photo/cafs-logo.png'
import cafsIcone from '../../assets/images/photo/cafs-icone.png'

const scrollAnimation = keyframes`
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 19));
    }
`;

const SliderContainer = styled.div`
    background: white;
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-top: 2em;
`;

const SlideTrack = styled.div`
    animation: ${scrollAnimation} 30s linear infinite;
    display: flex;
    width: calc(250px * 15);
`;

const Slide = styled.div`
    max-height: 100px;
    max-width: 250px;
    margin: 0 2em;
`;

const Image = styled.img`
    height: auto;
    width: auto;
`;

const ImageGrande = styled.img`
    height: 100px;
    width: auto;
`;

const Parceiros = () => {
    return (
        <SliderContainer>
            <SlideTrack>
                <Slide>
                    <Image src={Microsoft} alt="" />
                </Slide>
                <Slide>
                    <Image src={Solarwinds} alt="" />
                </Slide>
                <Slide>
                    <Image src={parceiro1} alt="" />
                </Slide>
                <Slide>
                    <Image src={Dell} alt="" />
                </Slide>
                <Slide>
                    <Image src={Equinix} alt="" />
                </Slide>
                <Slide>
                    <ImageGrande src={Grafana} alt="" />
                </Slide>
                <Slide>
                    <ImageGrande src={b3} alt="" />
                </Slide>
                <Slide>
                    <Image src={intelbras} alt="" />
                </Slide>
                <Slide>
                    <Image src={Intel} alt="" />
                </Slide>
                <Slide>
                    <Image src={Appdynamics} alt="" />
                </Slide>
                <Slide>
                    <ImageGrande src={atlassian} alt="" />
                </Slide>
                <Slide>
                    <Image src={parceiro2} alt="" />
                </Slide>
                <Slide>
                    <Image src={kaspersky} alt="" />
                </Slide>
                <Slide>
                    <Image src={rundeck} alt="" />
                </Slide>
                <Slide>
                    <Image src={zabbix} alt="" />
                </Slide>
                <Slide>
                    <Image src={axis} alt="" />
                </Slide>
                <Slide>
                    <ImageGrande style={{ width: '200px', height: 'auto' }} src={d42} alt="" />
                </Slide>
                <Slide>
                    <ImageGrande src={aws} alt="" />
                </Slide>
                <Slide>
                    <ImageGrande style={{ backgroundColor: '#292929' }} src={xlabs} alt="" />
                </Slide>
            </SlideTrack>
        </SliderContainer>
    );
};

export default Parceiros;

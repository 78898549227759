import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import Captcha from "../Captcha";
import { ReactComponent as Sensebot } from '../../assets/images/icon/sensebotColorido.svg';

const Container = styled.div`
    width: 350px;
    height: ${props => (props.open ? "450px" : "35px")};
    background-color: white;
    position: fixed;
    bottom: 0px;
    right: 10px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    box-shadow: 0px 0px 8px .5px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    padding: 4px 8px;
    transition: height 0.4s;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    z-index: 1000000;

    & h3 {
        font-family: ${style.typography.fontFamily.regular};
        font-size: 16px;
        color: ${style.colors.sensebot};
        width: 100%;
        cursor: pointer;
        display: flex;

        & svg {
            margin-right: 1rem;
        }
    }

    & iframe {
        border: none;
        border-radius: 8px;
    }

    & span {
        position: absolute;
        top: ${props => props.count < 1 ? '-140px' : '-70px'};
        right: 0px;
        width: ${props => props.count < 1 ? '200px' : '350px'};
        height: ${props => props.count < 1 ? '130px' : 'auto'};
        box-sizing: border-box;
        padding: 1rem;
        border-radius: 8px;
        background-color: #ff8c00;
        text-align: center;
        font-family: ${style.typography.fontFamily.medium};
        color: white;
        font-size: 14px;
        box-shadow: 0px 0px 8px .5px rgba(0, 0, 0, 0.4);
        animation: ${props => props.count < 1 ? 'shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both' : 'scale-out-hor-right 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both'};
        display: ${props => props.count > 2 ? 'none' : 'flex'};

        @keyframes shake-bottom {
            0%,
            100% {
                transform: rotate(0deg);
                transform-origin: 50% 100%;
            }
            10% {
                transform: rotate(2deg);
            }
            20%,
            40%,
            60% {
                transform: rotate(-4deg);
            }
            30%,
            50%,
            70% {
                transform: rotate(4deg);
            }
            80% {
                transform: rotate(-2deg);
            }
            90% {
                transform: rotate(2deg);
            }
        }

        @keyframes scale-out-hor-right {
            0% {
                transform: scaleX(1);
                transform-origin: 100% 100%;
                opacity: 1;
            } 90% {
                transform: scaleX(1);
                transform-origin: 100% 100%;
                opacity: 1;
            }
            100% {
                transform: scaleX(0);
                transform-origin: 100% 100%;
                opacity: 1;
            }
        }

    }
`;

const Chatbot = () => {
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);
    const [mensagem, setMensagem] = useState(false);

    const containerRef = useRef(null);

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => setCount(count + 1), 8000)
        setTimeout(() => setOpen(true), 4000);
    }, [])

    useEffect(() => {
        if(open) {
            setTimeout(() => {
                setMensagem('Obrigado por testar! Entre em contato conosco para saber mais sobre o Sensebot e o Nexus!')
            }, 15 * 60 * 1000);

            setTimeout(() => {
                setOpen(false)
                setMensagem(false)
            }, 16 * 60 * 1000);

        }
    }, [open])

    return (
        <Container ref={containerRef} open={open} count={count}>
            <h3 onClick={() => { 
                setOpen(!open)
                setCount(count + 1)
            }}>
            <Sensebot width={22} height={22} />
                Sensebot
            </h3>
            {open ? mensagem && mensagem.length !== 0 ? <p style={{ color: style.colors.nexus, width: '100%', height: '70%', marginTop: '20%', fontSize: '1.2rem', fontFamily: style.typography.fontFamily.semiBold, textAlign: 'center' }}>{mensagem}</p> : <Captcha /> : null}
        </Container>
    );
};

export default Chatbot;
